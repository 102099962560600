/**
 * Created by 212544474 on 8/16/2016.
 */
define([
    'angular'

], function () {
    'use strict';


    var controllers =  angular.module('app.usersCtrl',[])
        .controller('usersListCtrl', ['$rootScope','$scope','$state','$log','GridFactory','AlertService','$timeout','ModalFactory','$uibModal','$parse','GridSaveFactory','ClientAdminFactory','GENERAL_CONFIG', 'USER_SETTINGS', 'workspaceFactory', 'GlobalService', usersListCtrl])
        .controller('allUsersListCtrl', ['$rootScope','$scope','$state','$log','GridFactory','AlertService','$timeout','ModalFactory','$uibModal','$parse','GridSaveFactory','ClientAdminFactory','GENERAL_CONFIG', 'USER_SETTINGS', 'UserAdminFactory', allUsersListCtrl])
        .controller('userCtrl', ['$rootScope','$scope','$state','$log','AlertService','$timeout','$uibModalInstance', 'JsonObjectFactory','GENERAL_CONFIG', userCtrl])
        .controller('userDetailCtrl', ['$rootScope','$scope','$state','$log','AlertService','$timeout','UserAdminFactory','SERVER_CONFIG','GENERAL_CONFIG','USER_SETTINGS', userDetailCtrl])
        .controller('userModuleCtrl', ['$filter','$state','$log','AlertService','$timeout','UserAdminFactory','USER_SETTINGS','JsonObjectFactory', 'TemplateAdminFactory','$uibModal','GENERAL_CONFIG','GlobalService','TemplateAssignFactory', 'SERVER_CONFIG', userModuleCtrl])
        .controller('userBusinessCtrl', ['$rootScope','$scope','$state','$log','AlertService','$timeout','UserAdminFactory','$window', 'USER_SETTINGS','JsonObjectFactory','GlobalService','SERVER_CONFIG','GENERAL_CONFIG', userBusinessCtrl])
        .controller('userAuditCtrl', ['$scope','$state','$log','AlertService','$timeout','JsonObjectFactory','GENERAL_CONFIG', 'USER_SETTINGS','UserAdminFactory', 'GlobalService', userAuditCtrl])
        .controller('userSettingsCtrl', ['$scope','$state','$log','AlertService','$timeout','JsonObjectFactory','GENERAL_CONFIG', userSettingsCtrl])
        .controller('usersAddCtrl', ['$rootScope','$scope','$state','$log','AlertService','$timeout','$uibModalInstance', 'UserAdminFactory','SERVER_CONFIG','GENERAL_CONFIG', usersAddCtrl])
        .controller('templatePreviewController',['$scope','$state','$log','AlertService','$timeout','JsonObjectFactory', 'TemplateAdminFactory','templateDetails','$uibModalInstance','GENERAL_CONFIG', templatePreviewController])
        .controller('userAssignCtrl', ['$uibModalInstance', 'GlobalService', 'JsonObjectFactory', 'GENERAL_CONFIG', 'AlertService', 'rowData', 'UserAdminFactory', userAssignCtrl])
        .controller('userExpiryReportCtrl', ['$rootScope','$scope','$state','$log','GridFactory','AlertService','$timeout','ModalFactory','$uibModal','$parse','GridSaveFactory','ClientAdminFactory','GENERAL_CONFIG', 'USER_SETTINGS', 'workspaceFactory', 'GlobalService', userExpiryReportCtrl])
        .controller('userReportCtrl', ['$rootScope','$scope','$state','$log','AlertService','$timeout','$uibModalInstance', 'UserAdminFactory','SERVER_CONFIG','GENERAL_CONFIG', 'userReportData', 'userExpiryClientKey','$filter', userReportCtrl])


    //--- PARENT CONTROLLER USE  $scope.$parent.ctrl.[method / var]
    function userCtrl($rootScope,$scope,$state,$log,AlertService,$timeout,$uibModalInstance, JsonObjectFactory,GENERAL_CONFIG) {
        var vm = this;
        vm.user_id = $state.params.data.user_id;
        vm.title = "Loading Details ";
        vm.ssoErrorMessage = false;
        $scope.baseURLs = {};
        $scope.baseURLs.admin = GENERAL_CONFIG.admin_api_url;
        $scope.baseURLs.api = GENERAL_CONFIG.base_url;
        $scope.baseURLs.custom = GENERAL_CONFIG.custom_api_url;

        vm.parentString = 'admin.users-list';
        if( $state.current.parent === 'admin.users-list-module.user'){
            vm.parentString = 'admin.users-list-module';
        }


        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        };
        vm.setTitle = function(title){
            vm.title = title;
        };

        vm.refresh = function(){
            $rootScope.$broadcast("gridRefresh", true);
        };

        vm.getClientDe = function (val) {
            // alert(val);
            var params = {
                "client_name": vm.client_name,
                "user_type": vm.user_type,
                "client_active": vm.client_active,
                "updated_by": vm.updated_by,
                "updated_on": vm.updated_on,
            };

            return   JsonObjectFactory.getJSONObj(GENERAL_CONFIG.admin_api_url + '/fetchClients', params).then(function (data) {

                if (data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null') {
                    AlertService.add("error", data.errorMessage);
                } else {
                    if (data.jsonObject.length) {
                        return data.jsonObject;
                    }
                }
            });
        };

        $scope.headerData = {
            globalParams : angular.copy(GlobalService.globalParams),
            userSettingsData : angular.copy(USER_SETTINGS),
            serverConfigData : angular.copy(SERVER_CONFIG),
            generalConfigData : angular.copy(GENERAL_CONFIG)
        }
    }



    function usersAddCtrl($rootScope,$scope,$state,$log,AlertService,$timeout,$uibModalInstance,UserAdminFactory,SERVER_CONFIG,GENERAL_CONFIG) {
        var vm = this;
        vm.title = "Add User(s)";
        vm.user = {};
        vm.loading = false;
        vm.display = "none";
        vm.sso_id = "";
        vm.active = 1;
        vm.updating = false;
        vm.settings = SERVER_CONFIG.configSettings;

        /** LDAP Search by SSO ID logic moved to LDAP Search by (Pwc) Email Id.**/
        vm.callType = 'pwc_ldap';
        vm.email_id;


        //TODO get this from a settings file that gets it from db
        vm.user_types = [
            {label:"User" , value: 1 },
            {label:"Editor" , value: 2},
            {label:"Admin" , value: 3 },
            {label:"Developer" , value: 4 },
            {label:"Super Admin" , value: 5 }
        ];



        vm.user_type = vm.user_types[0];


        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        };


        vm.checkLength = function(event){

            var code = event.keyCode || event.which;
            if (code === 13) {
                vm.findUser();
            }


            if( vm.sso_id.length === 9){
                vm.ssoErrorMessage = false;
                return true;
            }
         }

        vm.findUser = function(){
           /** LDAP Search by SSO ID logic moved to LDAP Search by (PwC) Email Id.**/
        	var serviceUrl = '';
        	var serviceUrlLocalhost = '';
        	if(vm.callType==='pwc_ldap'){
        		serviceUrl = '';//SERVER_CONFIG.configSettings.pwc_ldap_base_url+vm.email_id+'/profile?addLdapAttributes=true&registerUserIfNotFoundOnEmailLookup=false';
        		serviceUrlLocalhost = '';//SERVER_CONFIG.configSettings.pwc_ldap_base_url_localhost+vm.email_id+'/profile?addLdapAttributes=true&registerUserIfNotFoundOnEmailLookup=false';
        	}else{
        		if( vm.sso_id.length !== 9){
                    vm.ssoErrorMessage = true;
                    return false;
                }
        	}
        	console.log("LDAP serviceUrl ::",serviceUrl);
            vm.user = {};
            var data ={user_id:vm.sso_id,email_id:vm.email_id,call_type:vm.callType}// {user_id:vm.sso_id,call_type:vm.callType,email_id:vm.email_id,service_url:serviceUrl,service_url_localhost:serviceUrlLocalhost};
            vm.loading = true;
            vm.display = "none";

            UserAdminFactory.findUser(data).then(function (data) {
                if( data && data.callSuccess === "1" ) {
                   // vm.user = data.jsonObject[0];
                   // vm.display = (vm.user.in_system === 1 ) ? 'update' : 'new';
                    vm.user = data.user;
                    vm.display = (vm.user.active_status === 1 ) ? 'update' : 'new';
                }else{
                    vm.display = "error";
                }
                vm.sso_id = "";
                vm.loading = false;
            });

        };

        /** Remove this before delivering this code to develop branch
        vm.startNotificationEngine = function(){
        	 var data = {};
        	 var notificationPayLoad = [{newUserSsoId: "400368673",newUserEmailId:"mallesh.nagothi@pwc.com"}];
        	 vm.loading = true;
             data.src_system = "GTW";
             data.notification_data = JSON.stringify(notificationPayLoad);
             console.log("DATA ::",data);
             UserAdminFactory.startNotificationEngine(data).then(function (data) {
                 if( data && data.callSuccess === "1" ) {
                    // vm.user = data.jsonObject[0];
                    // vm.display = (vm.user.in_system === 1 ) ? 'update' : 'new';
                     vm.user = data.user;
                     vm.display = (vm.user.active_status === 1 ) ? 'update' : 'new';
                 }else{
                     vm.display = "error";
                 }
                 vm.sso_id = "";
                 vm.loading = false;
             });

        }**/


        vm.addUser = function(){
        	/** LDAP Search by SSO ID logic moved to LDAP Search by (PwC) Email Id.**/
            if( vm.updating === true){
                AlertService.add("warning", "On moment, request is still processing.", 4000);
            }

            var data = {};
            vm.updating = true;
            data.sso_id = vm.user.sso_id;
            data.active = vm.active;
            data.user_type = vm.user_type.value;
            data.email = vm.email_id;
            data.service_url = SERVER_CONFIG.configSettings.pwc_ldap_base_url+vm.email_id+'/profile?addLdapAttributes=true&registerUserIfNotFoundOnEmailLookup=false';
            data.service_url_localhost = SERVER_CONFIG.configSettings.pwc_ldap_base_url_localhost+vm.email_id+'/profile?addLdapAttributes=true&registerUserIfNotFoundOnEmailLookup=false';
            var notificationPayLoad = [{newUserSsoId: "",newUserEmailId:""}];
            notificationPayLoad[0].newUserSsoId = vm.user.sso_id;
            notificationPayLoad[0].newUserEmailId = vm.email_id;
            data.notification_data = JSON.stringify(notificationPayLoad);

            UserAdminFactory.addUser(data).then(function (data) {
                if( data && data.callSuccess === "1" ) {
                    AlertService.add("success", "User has been added", 2000);
                    vm.editUser(vm.user.sso_id, vm.user.client_key);
                    vm.updating = false;
                    $rootScope.$emit('gridUpdate', {sso_id:data.sso_id });
                }else{
                	 AlertService.add("warning",data.errorMessage, 2000);
                	 vm.updating = false;
                }
            });

        };

        vm.editUser = function(sso_id,client_key){
          vm.cancel();
            var _data = {user_id:sso_id, selected_client_key : client_key };
            var parentString = 'admin.users-list.user.details';


            if( $state.current.name === 'admin.users-list-module'){
                parentString = 'admin.users-list-module.user.details';
            }

            $state.go(parentString ,  {data:_data});
        };



    }


    function userDetailCtrl($rootScope,$scope,$state,$log,AlertService,$timeout,UserAdminFactory,SERVER_CONFIG,GENERAL_CONFIG,USER_SETTINGS) {
        var vm = this;
        vm.user_id = $state.params.data.user_id;
        vm.user = {};
        vm.loading = false;
        vm.client = {};
        vm.clientList = [];
        vm.settings = SERVER_CONFIG.configSettings;
        vm.defaultClientData = [];
        vm.crudLoading  = false;


        vm.isDefaultClientAllowed = function(){
        	console.log("Selected User Type ", vm.user_type);
        	if(vm.user_type.value>3){
    			var foundDefaultClient = false;
            	_.each(vm.clientList,function(item){
                	if(item.client_key===3){
                		foundDefaultClient = true;
                		console.log("in side isDefaultClientAllowed() Found Default Client ::::");
                	}
                })
            	if(!foundDefaultClient && vm.defaultClientData.length>0){
            		vm.clientList.push(vm.defaultClientData[0]);
            	}
        	}else{
        		angular.forEach(vm.clientList,function(item,index){
                	console.log("Item ::",item.client_key);
                	if(item.client_key==3){
                		vm.clientList.splice(index,1);
                		console.log("in side isDefaultClientAllowed() vm.clientList ::::",vm.clientList);
                	}
                })
        	}
        }




        //TODO get this from a settings file that gets it from db
        vm.user_types = [
            {label:"User" , value: 1 },
            {label:"Editor" , value: 2},
            {label:"Admin" , value: 3 },
            {label:"Developer" , value: 4 },
            {label:"Super Admin" , value: 5 }
        ];

        vm.role_types = ["IT", "External", "AC", "Functional"];

        vm.client_user_types = [
            {label:"User" , user_type_key: 1 },
            {label:"Editor" , user_type_key: 2},
            {label:"Admin" , user_type_key: 3 },
            {label:"Developer" , user_type_key: 4 },
            {label:"Super Admin" , user_type_key: 5 }
        ];



        vm.active = "0";
        vm.user_type = {};


        vm.changeClient = function(_client){
            _client.updated = true;
        };



        function setUser(){
            vm.active = parseInt(vm.user.active_status);
            var index = _.findIndex(vm.user_types, { 'value': vm.user.user_type_key});
            vm.user_type = vm.user_types[index];
            //var roleIndex = _.findIndex(vm.role_types, {'value': vm.user.role_type_key});
            vm.role_type = vm.user.role;
            $scope.$parent.ctrl.setTitle( vm.user.display_name + " - ( " +  vm.user.sso_id + " )");
            console.log("vm.user_type ===============",vm.user_type);
            if(vm.user_type.value<=3){
            	angular.forEach(vm.clientList,function(item,index){
                	console.log("Item ::",item.client_key);
                	if(item.client_key==3){
                		vm.clientList.splice(index,1);
                		console.log("in side setUser() vm.clientList ::::",vm.clientList);
                	}
                })
            }
        }

        vm.getData = function(){
            var data = {};
            data.user_id = vm.user_id
            vm.loading = true;

           console.log("---------------------THIS SHOULD BE CALLED-----------------------");


            UserAdminFactory.getUserDetails(data).then(function (data) {
                if( data && data.callSuccess === "1" ) {
                    vm.user = data.user;
                    setUser();
                }else{
                    AlertService.add("error", "User could not be found.", 4000);
                }
                vm.loading = false;
            });


            var loadClientsUser = function () {

                UserAdminFactory.getUserClients({user_id:data.user_id} ).then(function (data) {
                    if (data.data.clientResult && data.data.error === '') {
                        vm.clientList = data.data.clientResult;
                        console.log("vm.defaultClientData ",vm.clientList);
                        if(vm.clientList && vm.clientList.length > 0){
                        	$rootScope.$broadcast('selectedUserClientsList', vm.clientList);
                        }
                        _.each(vm.clientList,function(item){
                            var filter = _.filter(USER_SETTINGS.user.clients,{client_key: item.client_key});
                           // if((_.filter(USER_SETTINGS.user.clients,{client_key: item.client_key})[0].user_type_key) <= 1){ // here 1 means user_type_key
                           if(filter && filter[0] && filter[0].user_type_key <=1 ){
                                 //   item.disbaled = true;
                            }else{
                               // item.disbaled = false;
                            }
                        	if(item.client_key==3){
                        		vm.defaultClientData.push(item);
                        		console.log("vm.defaultClientData ::::",vm.defaultClientData);
                        	}
                        })
                    } else {
                        AlertService.add("error", "Problem getting clients.");
                        return;
                    }

                });
            };

            loadClientsUser();

        }


        vm.getData();


        vm.save = function(){


            var data = {};
            data.sso_id = vm.user.sso_id;
            data.active = vm.active;
            data.client_active = vm.client_active;
            data.user_type = vm.user_type.value;
            data.role = vm.role_type;
            if(vm.crudLoading){
                AlertService.add('warning',"Please wait while we save this request",4000);
                return;
            }

            var clientList = [];
            _.each(vm.clientList, function(value,key){

                if(value.updated){
                    var options = {
                        "client_key": value.client_key,
                        "active": value.active,
                        "user_type_key": value.user_type_key,
                    };

                    clientList.push(options);
                }

            });



            vm.crudLoading = true;
            data.clients = JSON.stringify(clientList);

            UserAdminFactory.updateUser(data).then(function (data) {
                if( data && data.callSuccess === "1" ) {
                    if(vm.user_id != USER_SETTINGS.user.sso_id){
                        vm.getData();
                    }
                    vm.crudLoading = false;
                    AlertService.add("success", "User has been updated", 2000);
                    $rootScope.$emit('gridUpdate', {sso_id:data.sso_id });
                }else{
                    vm.getData();
                }

            });


        };

        vm.cancel = function(){
            $scope.$parent.ctrl.cancel();
        };

    }



    function userModuleCtrl($filter,$state,$log,AlertService,$timeout,UserAdminFactory,USER_SETTINGS,JsonObjectFactory,TemplateAdminFactory,$uibModal,GENERAL_CONFIG,GlobalService,TemplateAssignFactory, SERVER_CONFIG) {
        var vm = this;
        // vm.search;
        // vm.filters.me = [];
        // vm.filters.me_list = {};
        // vm.filters.me_list.selected = [];
        // vm.filters.me_list.name = "ME(s)"
        // vm.filters.me_list.values = [];

        /** Template Modules Code START Here**/
        initializeUserModuleCtrl('new');


        /** Initializes User Module Screen/Controller**/
        function initializeUserModuleCtrl(_screenMode){
        	vm.userSettings = USER_SETTINGS;
            vm.settings = SERVER_CONFIG.configSettings;
            vm.user = vm.userSettings.user;
        	vm.user_id = $state.params.data.user_id;
        	vm.logged_in_user = vm.userSettings.user.sso_id;
        	vm.userAssignNav = [];
            vm.loading = false;
            vm.allActiveClients = [];
            vm.allJurisdictions = [];
            vm.allTemplates = [];
            vm.allTemplatesList = [];
            vm.loading = false;
            vm.client_key = 0;
            vm.jurisdictionkey = 0;
            vm.templatekey = 0;
            vm.isReadyToApplyTemplate = false;
            vm.userPreviouslyHadModules = false;
            vm.foundTemplateModules = false;
            vm.global_params = angular.copy(GlobalService.globalParams);
            /** This is to display warning errors before forcefully applying a template as it removes all existing user modules**/
            vm.showValidationErrors = false;

            /** Running notes to GTW user. Need to find a better way to use this as a framework across GTW **/
            vm.userMessage = "Select Template using following filters in the following order \"Client\" -> \"Jurisdiciton\" -> \"Template\"";

            /** actionType is required based on Template Module Changes. This is an indirect impact as the components are written in reusable way **/
            vm.actionType = "UserAdminFactory";
            vm.row_primary_key = 0;
            vm.btnDisabled = "N";
            vm.filterMenu = filterMenu;
            vm.toggleAll = toggleAll;
            vm.isAllExpanded = isAllExpanded;
            vm.changeGroupRole = changeGroupRole;
            vm.setApplication = setApplication;

            vm.applicationFilter = {
                values: [],
                selected: [],
                name: 'Application(s)',
                model: null,
            };

            vm.templatesettings = {
                scrollableHeight: '200px',
                left: '94px',
                scrollable: true,
                enableSearch: true
            };

            vm.selectedTemplates = [];
            vm.selectedTempList = [];

            /** Initialize Client drop down with all active businesses **/
            getAllActiveBusinesses();

        }


        /** When user changes Business or Jurisdiction filters call this. This method is not called from anywhere, just keep it till end of demo**/
        vm.getData  = function(){
            getData();
        };


        function mergeNonMatchedTaskHierachy(jsonAry){
           var doProcess1 = function() {
                for(var i = 0 ; i < jsonAry.length; i++){
                    var chlObj = jsonAry[i];
                    process11(chlObj);
                    chlObj = process12(chlObj);
                }
            }
           var process11=function(chlObj){
            if (chlObj.children && chlObj.children.length) {
                for (var c = 0; c < chlObj.children.length; c++) {
                    var childTaskObj = chlObj.children[c];
                    // check any parent key for the current task
                    // if present, add
                    var childObjList = _.filter(chlObj.children,{parentTaskKey : childTaskObj.id});
                    if(childObjList.length){
                        childTaskObj.processed=true;
                        for(var i=0;i<childObjList.length;i++){
                            var childObj = childObjList[i];
                            if(childTaskObj.children)
                                childTaskObj.children.unshift(childObj);
                            else
                                childTaskObj.children=[childObj];
                            // process11(chlObj);
                        }
                    }
                }
            }
        }
        var process12=function(chlObj){
            chlObj.children=_.filter(chlObj.children,{parentTaskKey:null});
            return chlObj;
        }
        doProcess1();
        }

        function isAllExpanded() {
            return vm.filteredUserAssignNav && vm.filteredUserAssignNav.some(function(node) {
                return !node.collapsed;
            });
        }

        function toggleAll() {
            var expand = isAllExpanded();
            vm.filteredUserAssignNav.forEach(function(node) {
                node.collapsed = expand;
            });
        }

        function setApplication(item) {
            vm.searchTerm = '';
            if (item) {
                vm.selectedApplication = item;
                vm.filteredUserAssignNav = item.children;
            } else {
                filterMenu();
            }
        };

        function filterMenu(searchTerm) {
            var matchName = function(name) {
                return (name || '').toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
            };
            var filter = function(arr) {
                return angular.isArray(arr) && arr.filter(function(item) {
                    if (angular.isArray(item.children)) {
                        item.children = filter(item.children);
                    }
                    return matchName(item.name) || (item.children && item.children.length);
                });
            };
            if (searchTerm && vm.selectedApplication) {
                vm.filteredUserAssignNav = filter(angular.copy(vm.selectedApplication.children));
            } else if (searchTerm && !vm.selectedApplication) {
                vm.filteredUserAssignNav = filter(angular.copy(vm.applicationFilter.selected));
            } else if (!searchTerm && vm.selectedApplication) {
                vm.filteredUserAssignNav = vm.selectedApplication.children;
            } else {
                vm.filteredUserAssignNav = vm.applicationFilter.selected;
            }
        }

        function changeGroupRole(roleHigh, group) {
            if (angular.isArray(group.children)) {
                group.children.forEach(function(item) {
                    changeGroupRole(roleHigh, item);
                });
            }
            if (angular.isArray(group.roles)) {
                if (roleHight) {
                    group.role = _.get(group.roles, { value: Math.max.aplly(Math, _.map(group.roles, 'value'))});
                } else {
                    group.role = _.get(group.roles, { value: Math.min.aplly(Math, _.map(group.roles, 'value'))});
                }
            }
        }

        function getData() {
            vm.userAssignNav = [];
            var data = {client_key:vm.client_key};
            vm.loading = true;
            if(vm.jurisdictionkey){
            	data.jurisdiction_key = vm.jurisdictionkey;
            }else{
            	data.jurisdiction_key = 1; /** remove this after the demo **/
            }
            data.user_id =  vm.user_id;
            UserAdminFactory.getUserAssign(data).then(function (data) {
                if(data.callSuccess === "1" ){
                    vm.userAssignNav = data.jsonObject;
                    vm.applicationFilter.values = data.jsonObject.map(function(item) {
                        return { ...item, value: item.id };
                    });
                    vm.applicationFilter.selected = angular.copy(vm.applicationFilter.values);
                    mergeNonMatchedTaskHierachy(vm.userAssignNav);
                    var dropdownOption = _.find(vm.userAssignNav,{id: "5"});
                    setApplication(dropdownOption);
                    filterMenu();
                   //  toggleAll();
                    console.log("USER ASSIGN",vm.userAssignNav);
               //  mergeNonMatchedTaskHierachy(vm.userAssignNav,vm.taskList);

                    vm.userMessage = "Select Template from Template drop down and Click Appy Template for bulk assignment of Modules. OR Select appropriate ROLE against each activity to individually apply Modules.";
                }else {
                    if(data.errorMessage === "no access"){
                        AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                    }else{
                        AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                    }
                }
                vm.loading = false;
            });
        };

        /**
         * Retrieves all in user's clients
         */
        function getAllActiveBusinesses(){
        	vm.loading = true;
         	var data = {};
         	data.sso_id =  	vm.logged_in_user;
            data.selected_user_sso_id = vm.user_id;
            UserAdminFactory.getAllActiveBusinesses(data).then(function (data) {
                vm.loading = false;
                if(data.callSuccess === "1"){
                	vm.allActiveClients = JsonObjectFactory.arrObjToLower(data.jsonObject);
                	if(vm.allActiveClients.length <= 0){
                		AlertService.add("warning", "No Clients assigned to the selected user. Assign Client before assigning Modules", 4000);
                        vm.userMessage = "WARNING!! No Clients assigned to the selected user. Assign Client before assigning Modules";
                	}else{
                		vm.client_key = vm.userSettings.user.client_key;
                		vm.userMessage = "Please Select Business from following drop down.";
                		if(vm.allActiveClients.length>0){
                			vm.getJurisByClientKey(vm.client_key);
                		}
                        getExistingUserTemplateDetails();
                	}
                }else{
                      AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                      vm.userMessage = "ERROR!! Unable to perform Selected Action...";
                }
            });
        };

        /** Retrieves all Jurisdictions for given client **/
        function getAllJurisdictionsByClientKey(_clientKey,_autoSelection){
        	/** Empty them before refreshing jurisdictions as they are not valid any more **/
        	vm.allJurisdictions = [];
            vm.allTemplates = [];
         	vm.loading = true;
         	var data = {};
         	data.clientkey = _clientKey;
            UserAdminFactory.getJurisByBusiness(data).then(function (data) {
                vm.loading = false;
                if(data.callSuccess === "1"){
                	vm.allJurisdictions = JsonObjectFactory.arrObjToLower(data.jsonObject);
                	if(_autoSelection === "true"){
                		if(vm.allJurisdictions.length > 0){
                    		vm.userMessage = "System automatically selects United States as default jurisdiction. " +
                    				"If United States not found in the jurisdictions filter, system auto selects first Jurisdiction";
                    		angular.forEach(vm.allJurisdictions, function(jurisdiction, index) {
    	                    		/** System is trying to auto select United States as default Jurisdiction**/
                    				if(jurisdiction.jurisdictionkey === 1 ){
    	                    			vm.jurisdictionkey = jurisdiction.jurisdictionkey;
    	                    			console.log("System auto selected US as Juris:: vm.jurisdictionkey",vm.jurisdictionkey);
    	                    		}
                    		 });
                    		/** Pl. read above logic this is obvious logic. Thanx**/
                    		if(vm.jurisdictionkey <=0  ){
                    			/** First Item as Default Jurisdiction**/
                    			vm.jurisdictionkey = allJurisdictions[0].jurisdictionkey;
                    			console.log("System auto selected first item as Juris:: vm.jurisdictionkey",vm.jurisdictionkey);
                    		}
                    		getTemplatesByJurisdictionAndByClientKey(vm.jurisdictionkey,vm.client_key,_autoSelection);
                    	}else{
                    		vm.userMessage = "No Jurisdictions found. Please change Filter Criteria..";
                    	}
                	}else{
                		getTemplatesByJurisdictionAndByClientKey(vm.jurisdictionkey,vm.client_key,_autoSelection);
                	}
                }else {
                    if(data.errorMessage === "no access"){
                        AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                        vm.userMessage = "ERROR!! Unable to perform Selected Action...";
                    }else{
                        AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                        vm.userMessage = "ERROR!! Unable to perform Selected Action...";
                    }
                }
            });

        };

        /** This is when user changes Client drop down **/
        vm.getJurisByClientKey  = function(_clientKey){
        	getAllJurisdictionsByClientKey(vm.client_key,"true");
        };


        /** Retrieves all Templates for given client & Jurisdiction **/
        function getTemplatesByJurisdictionAndByClientKey(_jurisdictionKey,_clientKey,_autoSelection){
        	vm.allTemplates = [];
         	vm.loading = true;
         	var data = {};
         	data.action_code = 'p6bn0u';
         	data.jurisdictionKey = _jurisdictionKey;
         	data.clientKey		 = _clientKey;
            UserAdminFactory.getTemplatesByJAndClient(data).then(function (data) {
                vm.loading = false;
                /** shifted loading modules afer loading templates**/
                getData();
                if(data.callSuccess === "1"){
                	vm.allTemplates = JsonObjectFactory.arrObjToLower(data.jsonObject);
                	if(_autoSelection === "true"){
                		if(vm.allTemplates.length > 0){
                    		vm.userMessage = "Click \"Apply Template Module\" button. Before Applying Template Modules, System validates user\'s existing setup and Presents confirmation dialogue box.";
                    		//vm.templatekey = vm.allTemplates[0].templatekey;

                            for (var i = 0; i < vm.allTemplates.length; i++) {
                                var displayTemplateList = {}
                                displayTemplateList["id"] = vm.allTemplates[i].templatekey;
                                displayTemplateList["label"] = vm.allTemplates[i].templatename;
                                //vm.allTemplates.push(displayTemplateList);
                                vm.allTemplatesList.push(displayTemplateList);
                            }

                            angular.forEach(vm.selectedTempList, function(temp, key) {
                                for(var v = 0; v < vm.allTemplatesList.length; v++){
                                    if(vm.allTemplatesList[v].id == temp.templatekey){
                                        vm.selectedTemplates.push(vm.allTemplatesList[v]);
                                    }
                                }
                            });

                    		vm.isReadyToApplyTemplate = true;

                    	}else{
                    		vm.userMessage = "No Templates found for selected Filter criteria. Please Change Filter Criteria..";
                    		vm.isReadyToApplyTemplate = false;
                    	}
                	}
                }else {
                    if(data.errorMessage === "no access"){
                        AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                        vm.userMessage = "ERROR!! Unable to perform Selected Action...";
                    }else{
                        AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                        vm.userMessage = "ERROR!! Unable to perform Selected Action...";
                    }
                }
            });


        };

        /** This is when user changes Jurisdiction drop down **/
        vm.getTempaltesByJAndCKeys = function(_jurisdictionkey,_clientkey){
        	getTemplatesByJurisdictionAndByClientKey(_jurisdictionkey,_clientkey,"true");
        };

        /** When user clicks Apply Template Link - Validating**/
        vm.validateBeforeApplyTemplate = function(){

        	if(vm.isReadyToApplyTemplate){
        		vm.isReadyToApplyTemplate = false;
        		//Perform Validation whether the selected user has already having templates/modules assinged or not
        		vm.loading = true;
             	// var data = {};
             	// data.action_code = '33w8kh';
             	// data.ssoId = vm.logged_in_user;
             	// data.userSsoId	 = vm.user_id;
             	// data.templateKey = vm.templatekey;
             	// data.clientKey = vm.client_key;
             	vm.loading = true;
                // UserAdminFactory.validateUserTeamplatesMods(data).then(function (data) {
                //     vm.loading = false;

                //     if(data.callSuccess === "1"){
                //             //All Validations successful so Proceed to Apply Template Changes
                //         vm.userMessage = "System did not find any existing modules, hence applying changes...";
                //         vm.showValidationErrors = false;
                //         vm.applyTemplateChnages();

                //     }else if(data.callSuccess === "0") {
                //         vm.showValidationErrors = true;
                //         vm.userMessage = data.errorMessage;
                //         vm.userMessage = vm.userMessage + ' Click Proceed & Apply Link to Erase all existing user modules and Re-Apply Template Modules..';
                //     }else if(data.errorMessage === "no access"){
                //         vm.userMessage = "ERROR!! Unable to perform Selected Action...";
                //         vm.showValidationErrors = false;
                //         AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                //     }else{
                //         vm.showValidationErrors = false;
                //         vm.userMessage = "ERROR!! Unable to perform Selected Action...";
                //         AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                //     }
                // });

                var clobData = {
                    users : [],
                    templates : [],
                    isFinalList : 'Y'
                };
                
                clobData.users.push(vm.user_id);
                // angular.forEach(vm.userList, function(user){
                //     clobData.users.push(user.ssoId);
                // });
                
                if(vm.selectedTemplates.length > 0){
                    angular.forEach(vm.selectedTemplates, function(temp){
                        clobData.templates.push(temp.id);
                    });
                }
                
                TemplateAssignFactory.saveMultiTempUser(clobData, vm.user.client.client_key, vm.global_params).then(function (data) {
                    vm.loading = false;

                    if(data.data.callSuccess === "1"){
                        //All Validations successful so Proceed to Apply Template Changes
                        vm.userMessage = "System did not find any existing modules, hence applying changes...";
                        vm.showValidationErrors = false;
                        //vm.applyTemplateChnages();
                        AlertService.add("success", "Templates assigned successfully", 4000);

                    }else if(data.callSuccess === "0") {
                        vm.showValidationErrors = true;
                        vm.userMessage = data.errorMessage;
                        vm.userMessage = vm.userMessage + ' Click Proceed & Apply Link to Erase all existing user modules and Re-Apply Template Modules..';
                    }else if(data.errorMessage === "no access"){
                        vm.userMessage = "ERROR!! Unable to perform Selected Action...";
                        vm.showValidationErrors = false;
                        AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                    }else{
                        vm.showValidationErrors = false;
                        vm.userMessage = "ERROR!! Unable to perform Selected Action...";
                        AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                    }
                });
    		}else{
       		 	AlertService.add("warning", "Select Business -> Jurisdiction -> Template and Try to Apply Template", 4000);
        	}
        };

        /** Applying Template changes**/
        vm.applyTemplateChnages = function(){
        	vm.loading = true;
         	var data = {};
         	data.action_code = 'srcgfn';
         	data.ssoId = vm.logged_in_user;
         	data.userSsoId	 = vm.user_id;
         	data.templateKey = vm.templatekey;
         	data.clientKey = vm.client_key;
         	vm.loading = true;
            UserAdminFactory.validateUserTeamplatesMods(data).then(function (data) {
            	    vm.loading = false;

                    if(data.callSuccess === "1"){
                    	 //All Validations successful so Proceed to Apply Template Changes
                    	vm.userMessage = "System successfully applied changes.. Rereshing & Presenting User Modules...";
                    	vm.showValidationErrors = false;
                    	getData();
                    }else if(data.callSuccess === "0") {
                    	vm.userMessage = "System successfully applied changes.. Failed to Refresh & Present User Modules...";
                    	vm.showValidationErrors = false;
                    	AlertService.add("warning", data.errorMessage, 4000);
                    }else if(data.errorMessage === "no access"){
                    	vm.userMessage = "System successfully applied changes.. Failed to Refresh & Present User Modules...";
                		vm.showValidationErrors = false;
                		AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                    }else{
                    	vm.userMessage = "System successfully applied changes.. Failed to Refresh & Present User Modules...";
                       	vm.showValidationErrors = false;
                       	AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                    }
                });
        };

        /** **/
        vm.checkTemplateReadiness = function(){
        	if(vm.templatekey > 0 ){
        		vm.isReadyToApplyTemplate = true;
        	}else{
        		vm.isReadyToApplyTemplate = false;
        	}
        };

        /** Tries to find if the user already has templates set up**/
        function getExistingUserTemplateDetails(){
            if(vm.user_id){

              /**/

                var returnedData = {};
                vm.userMessage = "Searching for existing Template Modules for selected user....";
                vm.loading = true;
                var data = {};
                data.userSsoId = vm.user_id;
                data.client_key = vm.client_key;
                data.action_code = '9et55j';
                UserAdminFactory.getUserTemplateDetails(data).then(function (data) {


                    console.log("data ========================" , data);

                    vm.loading = false;
                     if(data.callSuccess === "1" ){
                         returnedData = JsonObjectFactory.arrObjToLower(data.jsonObject);
                         if(returnedData.length > 0 ){
                             vm.client_key = returnedData[0].client_key;
                             vm.jurisdictionkey = returnedData[0].jurisdictionkey;
                             vm.templatekey = returnedData[0].templatekey;
                             vm.userMessage = "WARNING!! Selected user already has Template Modules. Re-Applying Template will erase any existing overrides and re-apply template modules.";
                             vm.userPreviouslyHadModules  = true;

                             vm.selectedTempList = angular.copy(returnedData);

                            // To auto fill Jurisdictions
                     		 getAllJurisdictionsByClientKey(vm.client_key,"false");
                         }
                    }else{
                    	vm.userPreviouslyHadModules  = false;
                    	getAllJurisdictionsByClientKey(vm.client_key,"true");

                    }
                });
            }



        };


        /** Preview Link related functions   ctrl.removeAllAccess()  **/
        vm.removeAllAccess = function() {
        	var data = {}
        	data.user_sso_id = vm.user_id;
//        	data.action_code = 'qfeqhn';
        	console.log("removeAllAccess data ::: ",data);
            UserAdminFactory.removeAllAccess(data).then(function (data) {
                if( data && data.callSuccess === "1" ) {
                    AlertService.add("success", "User has been removed", 2000);
                    getData();
                }else{
                	 AlertService.add("warning",data.errorMessage, 2000);
                }

            });
        };


        /** Preview Link related functions**/
        vm.previewTemplate = function() {
        	console.log(vm.templatekey);
        	if(vm.templatekey > 0){

        		var data = {};
            	data.templatekey = vm.templatekey;
            	data.jurisdictionkey = vm.jurisdictionkey;
            	data.client_key = vm.client_key;

                var modalObj = {};
                modalObj.template = "app/components/admin/templates/template-preview-modal.html";
                modalObj.controler = "templatePreviewController as ctrl";
                modalObj.size = "lg";
                modalObj.keyboard = false;
                modalObj.backdrop = "static";
                vm.openModal(modalObj,data);
        	}else{
        		vm.userMessage = "Select Template from above Template drop down to Preview Template. If Templates are not available, change Filter Criteria..";
        	}
        };




        /** Opens Template Preview Modal**/
        vm.openModal = function (modalObj,_data) {

             var modalInstance = $uibModal.open({
                 animation: true,
                 templateUrl: modalObj.template,
                 controller: modalObj.controler,
                 size: modalObj.size,
                 backdrop: modalObj.backdrop,
                 keyboard: modalObj.keyboard,
                 resolve: {
                     templateDetails: function(){
                    	 console.log("_data ::",_data);
                    	 return _data;
                     }
                 }
             });

         };
    }

    function templatePreviewController($scope,$state,$log,AlertService,$timeout,JsonObjectFactory, TemplateAdminFactory,templateDetails,$uibModalInstance,GENERAL_CONFIG){
    	var vm = this;
    	vm.title = "Template Preview";
    	vm.template_details = templateDetails;
    	vm.templatekey = vm.template_details.templatekey;
    	vm.jurisdictionkey = vm.template_details.jurisdictionkey;
    	vm.client_key = vm.template_details.client_key;
    	vm.user_id = $state.params.data.user_id;
    	vm.showValidationErrors = false;

    	vm.templateAssignNav = [];
    	vm.templateModulesActionType = "TemplateAdminFactory";
    	vm.btnDisabled = "Y";

    	vm.loading = false;
    	vm.userMessage = "Retrieving Template Modules. Please wait.....";



    	function getTemplateData(){

        	//vm.templateAssignNav
        	 var data = {};
             vm.loading = true;
             data.user_id =  vm.user_id;
             data.row_primary_key = vm.templatekey;
             data.jurisdiction_key = vm.jurisdictionkey;
             data.client_key = vm.client_key;
             TemplateAdminFactory.getGtwModules(data).then(function (data) {
                 vm.loading = false;
                 if(data.callSuccess === "1" ){
                      vm.templateAssignNav = data.jsonObject;
                      vm.userMessage = "Following are the Template Modules for selected Template.";
                      vm.showValidationErrors = false;
                 }else {
                	 vm.showValidationErrors = true;
                     if(data.errorMessage === "no access"){
                     	vm.userMessage = "Unalbe to Refresh User Template Modules. Please revisit this page later...";
                         AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                     }else{
                     	vm.userMessage = "Unalbe to Refresh User Template Modules. Please revisit this page later...";
                         AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                     }
                 }
             });
        }

    	getTemplateData();

    	vm.cancel = function(){
    		$uibModalInstance.dismiss('cancel');
    	}

        vm.isAllExpanded = function () {
            return vm.templateAssignNav && vm.templateAssignNav.some(function(node) {
                return !node.collapsed;
            });
        };

        vm.toggleAll = function () {
            var expand = vm.isAllExpanded();
            vm.templateAssignNav.forEach(function(node) {
                node.collapsed = expand;
            });
        };
	}

    function userBusinessCtrl($rootScope,$scope,$state,$log,AlertService,$timeout,UserAdminFactory,$window,USER_SETTINGS,JsonObjectFactory,GlobalService,SERVER_CONFIG,GENERAL_CONFIG) {


        var vm = this;
        vm.user_id = $state.params.data.user_id;
        vm.bizzHierarchies = []; //Stored All Selected Bizz Hierarchies
        vm.defaultGroupObjKey = 0;
        vm.businessAssignNav = [];
        vm.enitiyAssignNav = [];
        vm.loading = false;
        vm.busHierarchyLoading = false;
        vm.busHierarchiesLoading = false;
        vm.busLoading = false;
        vm.entityLoading = false;
        vm.userSettings = USER_SETTINGS;
        vm.logged_in_user = vm.userSettings.user.sso_id;
        vm.legalEntities = [];
        vm.selectedLE = '';
        vm.txYearScenarioJcdData = [];
        vm.txYearScenarioJcdOptions = [];
        var isDataAvailable = false;
        vm.txyear_scenario_jcd = "";
        vm.taxYear  = GlobalService.globalParams.tax_year;
        vm.scenario = GlobalService.globalParams.scenario;
        vm.grpObjKey = 0;
        vm.reportingMonth=0;
        vm.renderingMode = 0;

        vm.taxYears = [];
        var user = vm.userSettings.user;
        vm.clients = user.clients;
        console.log(vm.clients, "which one");
		vm.client_key = $state.params.data.selected_client_key ||  vm.userSettings.user.client_key; // vm.clients[0].client_key;
		console.log("user ::",user);
        /*console.log("SERVER_CONFIG default jcd key::",SERVER_CONFIG.configSettings.default_jcd_key);
        console.log("SERVER_CONFIG default scenario::",SERVER_CONFIG.configSettings.default_scenario);*/
        console.log("SERVER_CONFIG default tax year::",SERVER_CONFIG.configSettings.default_tax_year);
        console.log("GlobalService.globalParams ::",GlobalService.globalParams);

        vm.bizzHierGrpObjDetails = [];
        vm.bizzHierGrpObjDetailsMap = []; //Group Obj Key as Key Item, Group Obj Details as Key Value
        vm.selectedGrpObjKeys = [];
        vm.selectedUserClientsList = [];


        $rootScope.$on('selectedUserClientsList',function(event,args){
        	vm.selectedUserClientsList = args;
        	console.log("vm.selectedUserClientsList ::",vm.selectedUserClientsList);
        });

        vm.cancelBus = function(){
            getData();
        }

        vm.updateSelectedGroupObjKeys = function(grpObjKey, selected_tax_year, selected_scenario){
        	console.log("grpObjKey ::",grpObjKey);
        	console.log("vm.selectedGrpObjKeys ::",vm.selectedGrpObjKeys);
        	console.log("vm.bizzHierarchies ::",vm.bizzHierarchies);
        	console.log("bizzHierGrpObjDetails ::",vm.bizzHierGrpObjDetails);
            console.log({selected_tax_year,selected_scenario});
            vm.selected_scenario = selected_scenario;
        	vm.selectedGrpObjKeys = [];
        	vm.bizzHierarchies = [];
        	var tempGrpObjKey = _.indexOf(vm.selectedGrpObjKeys, grpObjKey);
        	console.log("tempGrpObjKey ::",tempGrpObjKey);
        	console.log("vm.selectedGrpObjKeys ::",vm.selectedGrpObjKeys);
        	if(tempGrpObjKey<0){
        		vm.selectedGrpObjKeys = []; //empty it as we are not simultaneously displaying multiple bizz hierarchies
        		vm.selectedGrpObjKeys.push(grpObjKey); //place in selected grp objects array

        		var selBizHier = _.find(vm.bizzHierarchies, "group_obj_key");
        		//found in the cache
        		if(selBizHier && selBizHier.group_obj_key === grpObjKey){
        			angular.forEach(vm.bizzHierarchies, function(item, index) {
        				if(item.group_obj_key === grpObjKey ){
        					item.visible = true;
        					console.log("Marking Business Hierarchy as Visible GrpObjKey ::",item.group_obj_key);
        				}else{
        					item.visible = false;
        					console.log("Marking Business Hierarchy as Invisible GrpObjKey ::",item.group_obj_key);
        				}
            		 });
        		}else{
        			//not found in cache, pull from db
        			vm.getBizzHierarchyByGroupObjKey(grpObjKey);
        		}
        		//check box - checked/not checked logic
        		angular.forEach(vm.bizzHierGrpObjDetails, function(item, index) {
            		if(item.GROUP_OBJ_KEY === grpObjKey){
                        item.selected = true;
                        item.open = true;
            			console.log("Marking JCD Table Checkbox checked GrpObjKey ::",item.group_obj_key);
            		}else{
            			item.selected = false;
            			console.log("Marking JCD Table Checkbox checked GrpObjKey ::",item.group_obj_key);
            		}
        		});
        	}else{
        		_.pull(vm.selectedGrpObjKeys,grpObjKey);
        		angular.forEach(vm.bizzHierarchies, function(item, index) {
        			//marking invisible in bizzheries cache
    				if(item.group_obj_key === grpObjKey ){
    					item.visible = false;
    					console.log("Marking Business Hierarchy as Invisible GrpObjKey ::",item.group_obj_key);
    				}
        		 });
        		//check box - checked/not checked logic
        		angular.forEach(vm.bizzHierGrpObjDetails, function(item, index) {
            		if(item.GROUP_OBJ_KEY === grpObjKey){
                        item.selected = false;
            			console.log("Marking JCD Table Checkbox checked GrpObjKey ::",item.group_obj_key);
            		}
        		});
        	}
        	console.log("selectedGrpObjKeys ::",vm.selectedGrpObjKeys);
        }

        /**Retrieving Business Hierarchy for given Group Object Key and for given SSO**/
        vm.getBizzHierarchyByGroupObjKey = function(argGroupObjKey){
        	vm.busHierarchyLoading = true;
        	var data = {};
        	data.taxyear     = vm.taxYear;
    		data.sso_id      = vm.logged_in_user;
    		data.userSsoId   = vm.user_id;
    		data.grp_obj_key = argGroupObjKey;
    		data.isBusHierAssign = 'Y';
    		data.clientKey = vm.client_key;
    		console.log("Data ::",data);
    		vm.previewMode = 0;

        	if(vm.taxYear && argGroupObjKey){
        		UserAdminFactory.getBizzHierarchyBySsoByGroupObjKey(data).then(function (data) {
	        		  vm.busHierarchyLoading = false;
	                  if(data.callSuccess === "1" ){
	                	  if(data.jsonObject.length>0){

	                		  //make all biz hierarchies invisible
	                		  angular.forEach(vm.bizzHierarchies, function(item, index) {
	                			  item.visible = false;
	                  		  });
	                		  vm.businessAssignNav = data.jsonObject;
		                      var record = {group_obj_key: argGroupObjKey, value: vm.businessAssignNav, visible: true};
		                      vm.bizzHierarchies.push(record);
		                      console.log("data.jsonObject ", data.jsonObject);
		                      console.log("bizzHierarchies ", vm.bizzHierarchies);
		                      vm.isPreviewAvailable = true;
	                	  }else{
	                		  //make all biz hierarchies invisible
	                		  angular.forEach(vm.bizzHierarchies, function(item, index) {
	                			  item.visible = false;
	                  		  });
	                		  AlertService.add("warning", "No businesses assigned", 4000);
	                		  //+" Group Obj Key: "+argGroupObjKey
	                	  }
	                  }else {
	                      if(data.errorMessage === "no access"){
	                          AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
	                      }else{
	                          AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
	                      }
	                  }
	              });
        	}else{
        		 AlertService.add("warning", "Please make sure Tax Year, Client and Tax Years are selected", 4000);
        		 vm.loading = false;
        	}
        }

        /** Retrieving All Tax Years Based on Selected Client Key - Populates TAX YEARS Drop Down**/
        vm.getTaxYearsByClient = function(clientKey){
        	console.log("Inside getTaxYearsByClient()",clientKey);
        	/** Resetting Staged Bizz Hierarchy Data**/

        	if(clientKey>0){
        		vm.loading = true;
                var  params = {action_code: 'etxzx9', sso_id: vm.logged_in_user, client_key: clientKey};

                JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url +'/loadJsonObject', params).then(function (data) {
                    vm.loading = false;
                    console.log("getTaxYearsByClient() returned Data ::",data);
                    if(data.callSuccess === "1" ){
                    	if(data.jsonObject){
                    		vm.taxYears = _.map(data.jsonObject,'TAX_YEAR');
                    	}
                    	console.log("vm.taxYears ::",vm.taxYears);

                    }else{
                    	if(data.errorMessage === "no access"){
                            AlertService.add("error", "Sorry you do not have access to retrive Tax Years.", 4000);
                        }else{
                            AlertService.add("error", "Unalbe to retrieve Tax Years. If you continue to experience this issue please visit the help page to contact support.", 4000);
                        }
                    }

                });

        	}
        }

        vm.resetData = function (argClientKey){
        	vm.selectedGrpObjKeys = [];
        	vm.bizzHierarchies = [];
        	vm.bizzHierGrpObjDetails = [];
        	vm.defaultGroupObjKey = 0;
        	vm.getGroupObjKeyByClientTaxYrJcdScenario(); //get default group object key
            vm.getObjectKeysByClientAndTaxYear(); //Retrieving All Grp Object Keys for Given Client Key and Tax Year
            vm.getBizzHierGroupObjDetails(); //Retrieving all distinct GRP OBJ KEYS for given client across all years
        }

        /**Retrieving Group Object Details for all Bizz Hierarchies **/
        vm.getBizzHierGroupObjDetails = function(){
        	if(vm.client_key>=0){
        		vm.busHierarchiesLoading=true;
        		var  params = {action_code: 'eheat9', sso_id: vm.logged_in_user, client_key: vm.client_key};

                JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url +'/loadJsonObject', params).then(function (data) {
                    vm.busHierarchiesLoading = false;
                    console.log("getBizzHierGroupObjDetails() returned Data ::",data);
                    if(data.callSuccess === "1" ){
                    	vm.bizzHierGrpObjDetails = data.jsonObject;
                    	console.log("getBizzHierGroupObjDetails-->bizzHierGrpObjDetails ::",vm.bizzHierGrpObjDetails);
                    	angular.forEach(vm.bizzHierGrpObjDetails, function(item, index) {
                    		if(item.GROUP_OBJ_KEY === Number(vm.defaultGroupObjKey)){
                                item.selected = true;
                                item.open = true;
                    		}else{
                    			item.selected = false;
                    		}
                		});
                    }else{
                    	if(data.errorMessage === "no access"){
                            AlertService.add("error", "Sorry you do not have access to retrive Tax Years.", 4000);
                        }else{
                            AlertService.add("error", "Unalbe to retrieve Business Hierarchy Group Object Details. If you continue to experience this issue please visit the help page to contact support.", 4000);
                        }
                    }

                });
        	}else{
        		AlertService.add("warning", "Please Select Client", 4000);
        	}
        }


        /** Retrieving All Group Obj Keys based on selected Tax Year and Client - To Render Group Obj Master Table on UI**/
        vm.getObjectKeysByClientAndTaxYear = function(){
        	console.log("Get Group Obj Key By Tax Year and Client Fired");

        	vm.grpObjKeys = [];
        	if(vm.taxYear && vm.client_key){
        		vm.loading = true;
                var  params = {action_code: 'etbll9', sso_id: vm.logged_in_user, client_key: vm.client_key, taxyear: vm.taxYear};

                JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url +'/loadJsonObject', params).then(function (data) {
                    vm.loading = false;
                    if(data.callSuccess === "1" ){
                    	vm.grpObjKeys = data.jsonObject;
                    	vm.txYearScenarioJcdOptions = data.jsonObject;
                    	console.log("vm.txYearScenarioJcdOptions ::",vm.txYearScenarioJcdOptions);


                    }else{
                    	if(data.errorMessage === "no access"){
                            AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                        }else{
                            AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                        }
                    }
                    console.log("getObjectKeyByClientAndTaxYear() returned Data ::",data);
                });

        	}
        }

        /** Retrieving Default Group Obj Key based on TGT_CONFIG Default Tax Year/JCD/Scenario**/
       vm.getGroupObjKeyByClientTaxYrJcdScenario = function(){
    	   /*if(vm.defaultGroupObjKey>0){
    		   vm.updateSelectedGroupObjKeys(Number(vm.defaultGroupObjKey));
	   	   }else{
		   		vm.defaultGroupObjKey = SERVER_CONFIG.configSettings.default_bizz_hier_group_obj_key_ge;
	    		vm.updateSelectedGroupObjKeys(Number(vm.defaultGroupObjKey));
	   	   }*/

    	    //Following is commented because Group Object Key will be coming from TGT_CONFIG table
    	   vm.selectedGrpObjKeys = [];


        	if(vm.taxYear && vm.client_key >= 0 && vm.scenario){


        		vm.loading = true;
                var  params = {action_code: 'emall9', sso_id: vm.logged_in_user, client_key: vm.client_key, taxyear: vm.taxYear, default_scenario: vm.scenario };

                JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url +'/loadJsonObject', params).then(function (data) {
                    vm.loading = false;
                    console.log("getGroupObjKeyByClientTaxYrJcdScenario data::",data);
                    if(data.callSuccess === "1" ){
                    	if(data.jsonObject && data.jsonObject.length>0){
                    		vm.defaultGroupObjKey = data.jsonObject[0].GROUP_OBJ_KEY;
                    		if(vm.defaultGroupObjKey>0){
                    	       	vm.updateSelectedGroupObjKeys(vm.defaultGroupObjKey);
                    	    }
                    		console.log("getGroupObjKeyByClientTaxYrJcdScenario grpObjKeys::",vm.defaultGroupObjKey);
                    	}else{
                    		AlertService.add("warning", "No businesses assigned..", 4000);
                    	}
                    }else{
                    	if(data.errorMessage === "no access"){
                            AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                        }else{
                            AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                        }
                    }
                });
        	}else{
        		AlertService.add("warning", "Select: Tax Year Client Key and Scenario from Left Nav", 4000);
        	}
       }

       /** Removes all assigned business hierarchies for given SSO**/
       vm.removeAll = function(){
    	   console.log("Removes all assigned business hierarchies..");
    	   vm.loading = true;
           var  params = {action_code: 'ekkkk9', user_sso_id: vm.user_id, compliance: 'GTW'};
           console.log("removeAll() params ::",params);
           JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url +'/loadJsonObject', params).then(function (data) {
               vm.loading = false;
               console.log("removeAll data::",data);
               if(data.callSuccess === "1" ){
            	   AlertService.add("success", "Removed All Business Hierarchies for SSO::"+vm.sso_id, 4000);
            	   if(defaultGroupObjKey>0){
            		   vm.getBizzHierarchyByGroupObjKey(defaultGroupObjKey);
            	   }
               }else{
               	if(data.errorMessage === "no access"){
                       AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                   }else{
                       AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                   }
               }
           });

       }

        //TODO: SEND BACK BUSINESS(ES) TO DB CALL
        vm.saveBus = function(argGrpObjKey){

        	//Logic to find appropriate Bizz hierrachy based on Group Obj Key
        	console.log("saveBus() argGrpObjKey ::",argGrpObjKey);
           // var selBizHier = _.find(vm.bizzHierarchies, "group_obj_key");
           var selBizHier = _.find(vm.bizzHierarchies,{group_obj_key:argGrpObjKey})
    		console.log("saveBus() Request received to Save  ::",selBizHier);
    		var toBeSavedBizzHier = [];
    		var taxYr = "";
    		if(selBizHier){
    			toBeSavedBizzHier = selBizHier.value;
    			console.log("saveBus() Preparing to Save  ::",toBeSavedBizzHier);
    		}

            //GET ALL CHECKED THAT DO NOT HAVE CHILDREN PROPERTIES;
            var selectedArray = [];
            var isAll = "N";
            function getSelected(node){
            	var record = {};
            	if(node.selected && node.isleaf === 0){
            		isAll = "Y";
                	record.ME_KEY = node.mekey;
                	record.LE_KEY = '0';
                	record.IS_ALL = isAll;
                	record.GRP_OBJ_KEY = node.grpobjkey;
                	record.TAX_YEAR = node.taxyear;
                	taxYr = node.taxyear;
                    selectedArray.push(record);

                    _.forEach(node.children , function(value, key) {
                        getSelected(value)
                    });
                }else if(node.selected && node.isleaf === 1){
                	record.ME_KEY = node.mekey;
                	record.LE_KEY = '0';
                	record.IS_ALL = isAll;
                	record.GRP_OBJ_KEY = node.grpobjkey;
                	record.TAX_YEAR = node.taxyear;
                	taxYr = node.taxyear;
                	selectedArray.push(record);
                }else if(node.children){
                    _.forEach(node.children , function(value,key) {
                    	getSelected(value)
                    });
                }
            }
            _.forEach( toBeSavedBizzHier, function(value,key){
                getSelected(value)
            })
            console.log("saveBus() Bizz Hierarchy for Save :" , selectedArray);
            /**Incase user clicked Unassign-All and clicked save, then derive Tax Year from (Left Side) JCD/Scenario Bizz Hierarchy Table **/
            if(selectedArray.length<=0){
            	angular.forEach(vm.bizzHierGrpObjDetails, function(item, index) {
					if(item.selected){
						taxYr = item.TAX_YEAR;
						console.log("Selected Tax Year from Group Obj Details(LeftPanel) ::",taxYr);
					}
            	});
            }
            var service = JsonObjectFactory;
            var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=etbst9";
            var jsonObj = JSON.stringify(selectedArray);
            var jsonSettings = '{}';
            var params = _.extend({jsonObj:jsonObj}, params);
            params =  _.extend({jsonSettings:jsonSettings}, params);
            params.tax_year		= taxYr; //GlobalService.globalParams.tax_year; //"2017";
            params.sso_id		= vm.logged_in_user;
            params.userSsoId	= vm.user_id;
            params.compliance	= "GTW";
            params.grp_obj_key	= argGrpObjKey;
            params.scenario	= vm.selected_scenario;


            console.log("params ::",params);

            service.saveJSON(url , params).then(function(data){
                if(data.callSuccess === "1"){
                	console.log(data);
                	AlertService.add("success", "Business Hierarchy Saved Successfully.",4000);
                    vm.crudLoading = false;
                }else{
                    AlertService.add("error", "An error has occurred. If this error continues please go to the help page and contact support");
                    vm.crudLoading = false;
                }
            });
        }

        function getTxYearScenarioOptions(){
        	vm.loading = true;
            var  params = {action_code: 'dkm185', sso_id: vm.logged_in_user};

            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url +'/loadJsonObject', params).then(function (data) {
                vm.loading = false;
                if(data.callSuccess === "1" ){
                	vm.txYearScenarioJcdData = data.jsonObject;
                	angular.forEach(data.jsonObject, function(item){
                		var keyItem = item.TAX_YEAR +'-'+item.SCENARIO+'-'+item.JCD_KEY;
                		var val = 'TaxYear/Scenario/JCDKey:'+item.TAX_YEAR +'/'+item.SCENARIO+'/'+item.JCD_KEY+'-'+item.SCENARIO_DESC+'/'+item.JURISDICTION_CODE+'/'+item.DOMAIN_NAME+'/'+item.CHANNEL_NAME+']';
                		var record = { keyItem: val };
                        console.log("record ::",record);
                        vm.txYearScenarioJcdOptions.push(record);
                        console.log("vm.txYearScenarioJcdOptions ::",vm.txYearScenarioJcdOptions);
                    });
                }else{
                	if(data.errorMessage === "no access"){
                        AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                    }else{
                        AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                    }
                }
                console.log("getTaxYearScenarioJCDKey() returned Data ::",data);
            });

        }



        function getData() {
            var data = {};
            vm.businessAssignNav = [];
            vm.busLoading = true;
            data.isBusHierAssign = 'Y';

            data.tax_year	= taxYear;
            data.scenario 	= scenario;
            data.jcd_key	= jcdKey;
            data.userSsoId 	= vm.user_id;
            if(!data.tax_year || data.tax_year.length === "" || !data.scenario || data.scenario === "" || !data.jcd_key || data.jcd_key === "" ){
            	//getTxYearScenarioOptions();
            	if(vm.txYearScenarioJcdData.length > 0){
            		data.tax_year = txYearScenarioJcdData[0].TAX_YEAR;
            		data.scenario = txYearScenarioJcdData[0].SCENARIO;
            		data.jcd_key = txYearScenarioJcdData[0].JCD_KEY;



            		isDataAvailable = true;
            	}
            }else{
            	isDataAvailable = true;
            }
            if(isDataAvailable){
                UserAdminFactory.getUserBusiness(data).then(function (data) {
                    vm.busLoading = false;
                    if(data.callSuccess === "1" ){
                        vm.businessAssignNav = data.jsonObject;
                        console.log("data.jsonObject ", data.jsonObject);
                        if(data.jsonObject.length<=0){
                        	AlertService.add("warning", "No businesses assigned", 4000);
                        	//+" Scenario: "+scenario+" JCD Key: "+jcdKey
                        }

                    }else {
                        if(data.errorMessage === "no access"){
                            AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                        }else{
                            AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                        }
                    }
                });
            }else{
            	 AlertService.add("warning", "Tax Year/ Scenario / JCD Key not available.", 4000);
            	 vm.busLoading = false;
            }
        }


        vm.getTaxYearsByClient(vm.client_key); //Fills Tax Years Drop Down
        vm.getGroupObjKeyByClientTaxYrJcdScenario(); //get default group object key
        vm.getObjectKeysByClientAndTaxYear(); //Retrieving All Grp Object Keys for Given Client Key and Tax Year
        vm.getBizzHierGroupObjDetails(); //Retrieving all distinct GRP OBJ KEYS for given client across all years



        //getTxYearScenarioOptions();

        vm.setTxYearScenarioJcd = function(){
        	console.log("setTxYearScenarioJcd ::",vm.txyear_scenario_jcd);
        	var itemKey =  vm.txyear_scenario_jcd.split('-')[0];
        	console.log("itemKey ::",itemKey);

        	var itemVal = itemKey.split(':')[1];
        	console.log("itemVal ::",itemVal);

        	taxYear  = itemVal.split('/')[0];
        	scenario = itemVal.split('/')[1];
        	jcdKey   = itemVal.split('/')[2];
        	console.log("txYr ::",taxYear);
        	console.log("scnrio ::",scenario);
        	console.log("jcdKy ::",jcdKey);
        	getData();

        }

        vm.loadBizzHierarchyData = function(){
        	console.log("loadBizzHierarchyData ::");
        }

        function getEntities() {
            var data = {};
            vm.enitiyAssignNav = [];
            vm.entityLoading = true;
            UserAdminFactory.getUserEntity(data).then(function (data) {
                vm.entityLoading = false;
                if(data.callSuccess === "1" ){
                    vm.enitiyAssignNav = data.jsonObject;
                }
            });
        }

        //commented by mallesh
        //getEntities()

       vm.getLegalEntities = function(searchKey){
    	   /*vm.legalEntities = [
               {name:"User" , display_name: "User"  },
               {name:"Editor" , display_name: "Editor"},
               {name:"Admin" , display_name: "Admin" },
               {name:"Developer" , display_name: "Developer" },
               {name:"Super Admin" , display_name: "Super Admin" }
           ];*/

    	   console.log("returning LEs for SearchKEY = ", searchKey);



    	   vm.loading = true;
           var  params = {action_code: 'x8vwlj', tax_year: '2016', search_string: searchKey };
           JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url +'/loadJsonObject', params).then(function (data) {
               vm.loading = false;
               vm.legalEntities = data.jsonObject;
               console.log("getLegalEntities() returned Data ::",data);
           });

    	   return vm.legalEntities;
       }

       vm.addLE = function(selectedItem){
    	   console.log("Selected LE ::", selectedItem);
    	   vm.selectedLE = selectedItem.DISPLAY_NAME;
    	   //fire query to bring ME for selected LE
    	    vm.enitiyAssignNav = vm.legalEntities;

    	   vm.loading = true;
           var  params = {action_code: 'x8vwlj', tax_year: '2016', search_string: searchKey };
           JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url +'/loadJsonObject', params).then(function (data) {
               vm.loading = false;
               vm.legalEntities = data.jsonObject;
               console.log("getLegalEntities() returned Data ::",data);
           });

       }

        vm.cancelEntity = function(){
            getEntities();
        }

        //TODO: SEND BACK ENTITIES TO DB CALL
        vm.saveEntity = function(){

            //GET ALL CHECKED THAT DO NOT HAVE CHILDREN PROPERTIES;

            var selectedEntityList = _.filter(vm.enitiyAssignNav, { 'selected': true});
            console.log(" selectedEntityList  " ,  selectedEntityList);

        }
    }

    function userAuditCtrl($scope,$state,$log,AlertService,$timeout,JsonObjectFactory,GENERAL_CONFIG,USER_SETTINGS,UserAdminFactory,GlobalService) {
        var vm = this;
        vm.selectedAuditType;
        vm.user_settings = USER_SETTINGS;
        vm.userAudit = [];
        vm.bizzHierarchies = [];
        vm.userAuditMsg = false;
        vm.moduleAudit = [];
        vm.moduleAuditMsg = false;
        vm.businessAudit = [];
        vm.businessAuditMsg = false;
        vm.showDisplayMsg = true;
        vm.loading = false;
        vm.userSettings = USER_SETTINGS;
        var user = vm.userSettings.user;
        vm.clients = user.clients;
        vm.selectedClient = _.find(vm.clients, {
            client_key : user.client.client_key
        });
        vm.taxYear  = GlobalService.globalParams.tax_year;
        vm.logged_in_user = vm.userSettings.user.sso_id;
        vm.user_id = $state.params.data.user_id;
        vm.client_key = $state.params.data.selected_client_key ||  vm.userSettings.user.client_key;
        vm.jurisdictionkey = 0;
        vm.row_primary_key = 0;
        vm.btnDisabled = "Y";
        vm.filterMenu = filterMenu;
        vm.toggleAll = toggleAll;
        vm.isAllExpanded = isAllExpanded;
        vm.changeGroupRole = changeGroupRole;
        vm.setApplication = setApplication;
        var onLoadModulesArray;
        
        var finalModulesArray;
        vm.applicationFilter = {
            values: [],
            selected: [],
            name: 'Application(s)',
            model: null,
        };

        vm.auditTypes = [
            {
                id : 1,
                name : 'Details',
                value : 'UPDATE_USER_DETAILS',
                arrayName : 'userAudit'
            },{
                id : 2,
                name : 'Modules',
                value : 'MODULE_ASSIGN',
                arrayName : 'moduleAudit'
            },{
                id : 3,
                name : 'Businesses',
                value : 'BUSINESS_ASSIGN',
                arrayName : 'businessAudit'
            }
        ];

        //TODO get this from a settings file that gets it from db
        vm.user_types = [
            {label:"User" , value: 1 },
            {label:"Editor" , value: 2},
            {label:"Admin" , value: 3 },
            {label:"Developer" , value: 4 },
            {label:"Super Admin" , value: 5 }
        ];

        vm.client_user_types = [
            {label:"User" , user_type_key: 1 },
            {label:"Editor" , user_type_key: 2},
            {label:"Admin" , user_type_key: 3 },
            {label:"Developer" , user_type_key: 4 },
            {label:"Super Admin" , user_type_key: 5 }
        ];

        vm.active = 0;
        vm.user_type = vm.user_types[0];

        vm.resetAuditView = function(){
            vm.userAudit = [];
            vm.moduleAudit = [];
            vm.businessAudit = [];
            vm.showDisplayMsg = true;
            vm.selectedAuditType = "";
        };

        vm.getAuditData = function(auditType){
            var auditData = JSON.parse(auditType);
            vm.auditType = auditData.value;
            vm.showDisplayMsg = false;
            vm.loading = true;
            if(vm.auditType == 'UPDATE_USER_DETAILS'){
                vm.moduleAudit = [];
                vm.businessAudit = [];
            }else if(vm.auditType == 'MODULE_ASSIGN'){
                vm.userAudit = [];
                vm.businessAudit = [];
            }else{
                vm.userAudit = [];
                vm.moduleAudit = [];
            }
            UserAdminFactory.getAuditData(vm.auditType, vm.user_id, vm.selectedClient.client_key).then(function (data) {
                console.log(data);
                if( data && data.data.callSuccess === "1" ) {
                    if(data.data.jsonObj.length > 0){
                        if(auditData.name == "Businesses"){
                            vm.getBizzHierarchyByGroupObjKeyForAudit(data.data.jsonObj, JSON.parse(data.data.jsonObj[0].req_params).grp_obj_key, auditData.arrayName);
                        }else if (auditData.name == "Modules"){
                            console.log("We are into Modules");
                            getModulesData(data.data.jsonObj, JSON.parse(data.data.jsonObj[0].req_params)[0].client_key, auditData.arrayName);
                        }else{
                            loadClientsUser(vm.user_id, data.data.jsonObj, auditData.arrayName);
                        }
                    }else{
                        vm[auditData.arrayName] = [];
                        vm[auditData.arrayName + 'Msg'] = true;
                        vm.loading = false;
                    }
                }else {
                     AlertService.add("Error", "Failed to fetch assigned user data", 2000);
                }
            });
        }

        var loadClientsUser = function (user_id, hierarchyData, arrayName) {
            UserAdminFactory.getUserClients({user_id} ).then(function (data) {
                if (data.data.clientResult && data.data.error === '') {
                    vm.clientList = data.data.clientResult;
                    // console.log("vm.defaultClientData ",vm.clientList);
                    // _.each(vm.clientList,function(item){
                    //     var filter = _.filter(USER_SETTINGS.user.clients,{client_key: item.client_key});
                    //     if(item.client_key==3){
                    //         vm.defaultClientData.push(item);
                    //         console.log("vm.defaultClientData ::::",vm.defaultClientData);
                    //     }
                    // })
                    angular.forEach(hierarchyData, function(row){
                        var reqParams = JSON.parse(row.req_params);
                        row.dataRows = [];
                        row.dataRows.push(reqParams);
                        row.user_types = angular.copy(vm.user_types);
                        var index = _.findIndex(row.user_types, { 'value': parseInt(reqParams.user_type)});
                        row.user_type = parseInt(reqParams.user_type);
                        row.active = parseInt(reqParams.active);
                        row.clientList = [];

                        if(reqParams.clients.length > 0){
                            angular.forEach(reqParams.clients, function(paramRow){
                                if (_.find(vm.clientList, { client_key : paramRow.client_key })) {
                                    var editedClient = _.find(vm.clientList, { client_key : paramRow.client_key });
                                    row.client_user_types = angular.copy(vm.client_user_types);
                                    var index = _.findIndex(row.client_user_types, { 'user_type_key': parseInt(paramRow.user_type_key)});
                                    //editedClient.user_type_key = paramRow.user_type_key;
                                    //editedClient.active = paramRow.active;
                                    row.clientList.push(editedClient);
                                }
                            });
                        }
                        row.toggle = false;
                    });
                    vm[arrayName] = hierarchyData;
                    vm[arrayName + 'Msg'] = hierarchyData.length > 0 ? false : true;
                    vm.loading = false;
                } else {
                    AlertService.add("error", "Problem getting clients.");
                    return;
                }
            });
        };

        function getModulesData(hierarchyData, clientKey, arrayName) {
            vm.userAssignNav = [];
            var data = {client_key : clientKey};
            vm.loading = true;
            if(vm.jurisdictionkey){
            	data.jurisdiction_key = vm.jurisdictionkey;
            }else{
            	data.jurisdiction_key = 1; /** remove this after the demo **/
            }
            data.user_id =  vm.user_id;
            UserAdminFactory.getUserAssign(data).then(function (data) {
                if(data.callSuccess === "1" ){
                    vm.userAssignNav = data.jsonObject;
                    vm.applicationFilter.values = data.jsonObject.map(function(item) {
                        return { ...item, value: item.id };
                    });
                    vm.applicationFilter.selected = angular.copy(vm.applicationFilter.values);
                    mergeNonMatchedTaskHierachy(vm.userAssignNav);
                    var dropdownOption = _.find(vm.userAssignNav,{id: "5"});
                    setApplication(dropdownOption);
                    filterMenu();
                    console.log("USER ASSIGN", vm.userAssignNav);

                    vm.userMessage = "Select Template from Template drop down and Click Appy Template for bulk assignment of Modules. OR Select appropriate ROLE against each activity to individually apply Modules.";
                    //make all biz hierarchies invisible
                    vm.removeSelectedRoles = function(data){
                        angular.forEach(data, function(channel, index) {
                            if(channel.role){
                                channel.role = {};
                            }
                            
                            if(channel.children){
                                vm.removeSelectedRoles(channel.children)
                            }
                        });
                    };
                    vm.removeSelectedRoles(finalModulesArray);

                    angular.forEach(hierarchyData, function(row){
                        var reqParams = JSON.parse(row.req_params);
                        row.dataRows = [];
                        row.dataRows.push(reqParams);
                        var tempModulesHierarchy = angular.copy(finalModulesArray);
                        
                        vm.setActivitiesSelected = function(data){
                            angular.forEach(data, function(row){
                                angular.forEach(reqParams, function(editedRow){
                                    if (_.find(reqParams, { jcd_ta_key : row.jcd_ta_key })) {
                                        if(row.roles){
                                            if (_.find(row.roles, { value : editedRow.jcd_ta_role_key })) {
                                                var selectedRole = _.find(row.roles, { value : editedRow.jcd_ta_role_key });
                                                row.role = selectedRole;
                                            }
                                        }
                                    }
                                });
                                if(row.children){
                                    vm.setActivitiesSelected(row.children)
                                }
                            });
                        }

                        vm.setActivitiesSelected(tempModulesHierarchy);
                        row.modulesHierarchyList = tempModulesHierarchy;
                        row.toggle = false;
                    });
                    vm[arrayName] = hierarchyData;
                    vm[arrayName + 'Msg'] = hierarchyData.length > 0 ? false : true;
                    vm.loading = false;
                    onLoadModulesArray = angular.copy(hierarchyData);
                
                }else {
                    if(data.errorMessage === "no access"){
                        AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                    }else{
                        AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                    }
                }
                vm.loading = false;
            });
        };

        /**Retrieving Business Hierarchy for given Group Object Key and for given SSO**/
        vm.getBizzHierarchyByGroupObjKeyForAudit = function(hierarchyData, argGroupObjKey, arrayName){
        	vm.busHierarchyLoading = true;
        	var data = {};
        	data.taxyear     = vm.taxYear;
    		data.sso_id      = vm.logged_in_user;
    		data.userSsoId   = vm.user_id;
    		data.grp_obj_key = argGroupObjKey;
    		data.isBusHierAssign = 'Y';
    		data.clientKey = vm.client_key;
    		console.log("Data ::",data);
    		vm.previewMode = 0;
            vm.bizzHierarchies = [];

        	if(vm.taxYear && argGroupObjKey){
        		UserAdminFactory.getBizzHierarchyBySsoByGroupObjKey(data).then(function (data) {
                    vm.busHierarchyLoading = false;
                    if(data.callSuccess === "1" ){
                        if(data.jsonObject.length>0){

                            //make all biz hierarchies invisible
                            vm.setSelected = function(data, flag){
                            angular.forEach(data, function(item, index) {
                                item.selected = flag;
                                item.disableCheckbox = true;
                                
                                if(item.children){
                                    vm.setSelected(item.children, false)
                                }
                                });
                            };
                            vm.setSelected(data.jsonObject, false);
                            vm.businessAssignNav = data.jsonObject;

                            var record = {group_obj_key: argGroupObjKey, value: vm.businessAssignNav, visible: true};
                            vm.bizzHierarchies.push(record);
                            console.log("data.jsonObject ", data.jsonObject);
                            console.log("bizzHierarchies ", vm.bizzHierarchies);
                            vm.isPreviewAvailable = true;

                            angular.forEach(hierarchyData, function(row){
                            var reqParams = JSON.parse(row.req_params);
                            if(reqParams.length == undefined){
                                row.dataRows = [];
                                row.dataRows.push(reqParams);
                                var tempbussHierarchy = angular.copy(vm.bizzHierarchies);
                                
                                vm.setBussSelected = function(data, flag){
                                    angular.forEach(data, function(row){
                                        if (_.find(reqParams.jsonObj, { ME_KEY : row.mekey })) {
                                            row.selected = true;
                                        }
                                        if(row.children){
                                            vm.setBussSelected(row.children, true)
                                        }
                                    });
                                }

                                vm.setBussSelected(tempbussHierarchy[0].value, true);
                                row.businessHierarchyList = tempbussHierarchy;
                            }else{
                                row.dataRows = reqParams;
                            }
                            row.toggle = false;
                        });
                        vm[arrayName] = hierarchyData;
                        vm[arrayName + 'Msg'] = hierarchyData.length > 0 ? false : true;
                        vm.loading = false;
                        }else{
                            //make all biz hierarchies invisible
                            angular.forEach(vm.bizzHierarchies, function(item, index) {
                                item.visible = false;
                            });
                            AlertService.add("warning", "No businesses assigned", 4000);
                            //+" Group Obj Key: "+argGroupObjKey
                        }
                    }else {
                        if(data.errorMessage === "no access"){
                            AlertService.add("error", "Sorry you do not have access to do the requested action.", 4000);
                        }else{
                            AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                        }
                    }
                });
        	}else{
        		 AlertService.add("warning", "Please make sure Tax Year, Client and Tax Years are selected", 4000);
        		 vm.loading = false;
        	}
        }

        function mergeNonMatchedTaskHierachy(jsonAry){
            var doProcess1 = function() {
                for(var i = 0 ; i < jsonAry.length; i++){
                    var chlObj = jsonAry[i];
                    process11(chlObj);
                    chlObj = process12(chlObj);
                }
            }
            var process11=function(chlObj){
                if (chlObj.children && chlObj.children.length) {
                    for (var c = 0; c < chlObj.children.length; c++) {
                        var childTaskObj = chlObj.children[c];
                        // check any parent key for the current task
                        // if present, add
                        var childObjList = _.filter(chlObj.children,{parentTaskKey : childTaskObj.id});
                        if(childObjList.length){
                            childTaskObj.processed=true;
                            for(var i=0;i<childObjList.length;i++){
                                var childObj = childObjList[i];
                                if(childTaskObj.children)
                                    childTaskObj.children.unshift(childObj);
                                else
                                    childTaskObj.children=[childObj];
                                // process11(chlObj);
                            }
                        }
                    }
                }
            }
            var process12=function(chlObj){
                chlObj.children=_.filter(chlObj.children,{parentTaskKey:null});
                return chlObj;
            }
            doProcess1();
        }

        function isAllExpanded(data) {
            return data && data.some(function(node) {
                return !node.collapsed;
            });
        }

        function toggleAll(data) {
            var expand = isAllExpanded(data);
            data.forEach(function(node) {
                node.collapsed = expand;
            });
        }

        function setApplication(item) {
            vm.searchTerm = '';
            if (item) {
                vm.selectedApplication = item;
                vm.filteredUserAssignNav = item.children;
            } else {
                filterMenu();
            }
        };

        function filterMenu(data, searchTerm, arrayName, index) {
            var matchName = function(name) {
                return (name || '').toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
            };
            var filter = function(arr) {
                return angular.isArray(arr) && arr.filter(function(item) {
                    if (angular.isArray(item.children)) {
                        item.children = filter(item.children);
                    }
                    return matchName(item.name) || (item.children && item.children.length);
                });
            };
            if (searchTerm && vm.selectedApplication) {
                vm[arrayName][index].modulesHierarchyList = filter(angular.copy(onLoadModulesArray[index].modulesHierarchyList));
            } else if (searchTerm && !vm.selectedApplication) {
                vm[arrayName][index].modulesHierarchyList = filter(angular.copy(onLoadModulesArray[index].modulesHierarchyList));
            } else if (!searchTerm && vm.selectedApplication) {
                //vm.filteredUserAssignNav = vm.selectedApplication.children;
                if(arrayName){
                    vm[arrayName][index].modulesHierarchyList = angular.copy(onLoadModulesArray[index].modulesHierarchyList);
                }
                vm.filteredUserAssignNav = [];
                finalModulesArray = vm.selectedApplication.children;
            } else {
                vm.filteredUserAssignNav = vm.applicationFilter.selected;
            }
        }

        function changeGroupRole(roleHigh, group) {
            if (angular.isArray(group.children)) {
                group.children.forEach(function(item) {
                    changeGroupRole(roleHigh, item);
                });
            }
            if (angular.isArray(group.roles)) {
                if (roleHight) {
                    group.role = _.get(group.roles, { value: Math.max.aplly(Math, _.map(group.roles, 'value'))});
                } else {
                    group.role = _.get(group.roles, { value: Math.min.aplly(Math, _.map(group.roles, 'value'))});
                }
            }
        }
    }

    function userSettingsCtrl($scope,$state,$log,AlertService,$timeout,JsonObjectFactory,GENERAL_CONFIG) {

    }


    function usersListCtrl($rootScope,$scope,$state,$log,GridFactory,AlertService,$timeout,ModalFactory,$uibModal,$parse,GridSaveFactory,ClientAdminFactory,GENERAL_CONFIG, USER_SETTINGS, workspaceFactory, GlobalService) {
        var vm = this;
        vm.name = "userListCtrl";
        vm.data = [];
        vm.api = $state.current.api.get;
        // vm.filter = {
        //     client_key: JSON.stringify( USER_SETTINGS.user.client.client_key)
        // };
        vm.filter = USER_SETTINGS.user.client
        vm.clients = [];
        vm.clients = USER_SETTINGS.user.clients;
        console.log(vm.filter, "vm.filter");

        console.log(USER_SETTINGS, "USER_SETTINGS");
        var all_params_data = '';
        $scope.action_key = '32938';
        $scope.grid_key = '6013';
        $scope.screen_key = '11505';
        $scope.screen_key = workspaceFactory.activeScreen.screen_key;

        vm.types = [
            {name : "PwC Users", value : "0"},
            {name : "Non-PwC Users", value : "1"},
            {name : "All", value : "2"}
        ];
        vm.selectedType = vm.types[0];

        var filterParams = {
            tax_year: GlobalService.globalParams.tax_year,
            scenario: GlobalService.globalParams.scenario,
            jcd_key: GlobalService.globalParams.jcd_key,
            screen_key: "11505",
            user_key: vm.selectedType.value,
            client_key: USER_SETTINGS.user.client.client_key
        };
        var all_params = GridFactory.getExcelUrl(filterParams);

        angular.forEach(all_params, function (value, key) {
            all_params_data = all_params_data + '&' + key + '=' + value;
        });

        $scope.all_filter_data = all_params_data;

        vm.addUser = function(){
            var modalObj = {};
            modalObj.template = "app/components/admin/users/user-add-tpl.html";
            modalObj.controler = "usersAddCtrl as ctrl";
            modalObj.size = "lg";
            modalObj.keyboard = false;
            modalObj.backdrop = "static";
            $scope.openModal(modalObj, vm.data);
        };

        vm.refresh = function(){
            getData();
        };

         // get the clientList initail load of page
		vm.getClientList = function() {
            ClientAdminFactory.getAllClients().then(function(res) {
                vm.clients = [];
                angular.forEach($parse('data.clientResult.clientList')(res), function(client) {
                    vm.clients.push({
                        id: client.client_key,
                        name: client.client_name
                    });
                });
                // vm.clients = $parse('data.clientResult.clientList')(res);
            });
        };
       // vm.getClientList();

        vm.onClientChange = function() {
            var filterParams = {
                tax_year: GlobalService.globalParams.tax_year,
                scenario: GlobalService.globalParams.scenario,
                jcd_key: GlobalService.globalParams.jcd_key,
                screen_key: "11505",
                user_key: vm.selectedType.value,
                client_key: USER_SETTINGS.user.client.client_key
            };
            var all_params = GridFactory.getExcelUrl(filterParams);
    
            angular.forEach(all_params, function (value, key) {
                all_params_data = all_params_data + '&' + key + '=' + value;
            });
    
            $scope.all_filter_data = all_params_data;
            getData();
        };


        var gridUpdateListener = $rootScope.$on('gridUpdate', function(event, args) {
            getUpDate(args);
        });

        $scope.$on('$destroy', function () {
            $log.log("Unregistering listener");
            gridUpdateListener();
        });

        var getUpDate = function(args) {
            $scope.$broadcast("showDataRefresh", true);
            var params = args;
            var _filterObj = {};
            GridFactory.getDataUpdate(vm.api,params).then(function (data) {
                if (data.callSuccess === "1") {
                    $scope.$broadcast("showDataRefresh", false);
                    $scope.$broadcast("dataUpdate", {filter:_filterObj,data:data.data});
                } else {
                    $scope.$broadcast("showDataRefresh", false);
                    AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                }
            });
        }


        var getData = function() {
            $scope.$broadcast("showLoader", true);
            vm.loading = true;
            vm.gridData = [];
            vm.gridData.DATA = [];
            GridFactory.getDataCustom($state.current.api.get, {client_key:vm.filter.client_key, user_key:vm.selectedType.value}).then(function (data) {
            if (data.callSuccess === "1") {
                    vm.data = data;
                    $scope.$broadcast("dataLoaded", data);
                    $scope.$broadcast("showLoader", false);
                } else {
                    AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                }
            });
        }


        $timeout(function() {
            getData();
        }, 5);


        $scope.ctrlFn = function(_callName,_data,_type) {

            if(_type === 'columnTemplate'){
                GridSaveFactory.colTemplatesUpdate($state.current.api.grid_id,_callName,_data);
            }else if(_callName === 'userDetail'){
                _data = {user_id:_data.rowData.SSO_ID,selected_client_key:_data.rowData.CLIENT_KEY }
                $state.go('admin.users-list.user.details' ,  {data :_data });
            }else if( _callName === 'userDetailModule' ) {
                _data = {user_id:_data.rowData.SSO_ID }
                $state.go('admin.users-list-module.user.details' ,  {data :_data });
            }

        };



        //FOR ADD USER
        $scope.openModal = function (modalObj,_data) {
            $scope.rowData = _data.rowData;
            $scope.gridData = _data;
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: modalObj.template,
                controller: modalObj.controler,
                size: modalObj.size,
                backdrop: modalObj.backdrop,
                keyboard: modalObj.keyboard,
                resolve: {
                    rowData: function () {
                        return $scope.rowData;
                    },
                    gridData: function () {
                        return $scope.gridData;
                    }
                }
            });

            modalInstance.result.then(function (selectedItem) {
                $scope.selected = selectedItem;
            }, function () {
                $log.info('Modal dismissed at: ' + new Date());
                $log.info('$scope.rowData' , $scope.rowData);
            });
        };

    }

    function allUsersListCtrl($rootScope,$scope,$state,$log,GridFactory,AlertService,$timeout,ModalFactory,$uibModal,$parse,GridSaveFactory,ClientAdminFactory,GENERAL_CONFIG, USER_SETTINGS, UserAdminFactory) {
        var vm = this;
        vm.name = "userListCtrl";
        vm.data = [];
        vm.api = $state.current.api.get;
        vm.hideLoader = true;
        
        vm.filter = {
            client_key: JSON.stringify( USER_SETTINGS.user.client.client_key)
        };
        vm.usersList = {};

        //Multiselect dropdown config
        vm.clientList = {};
        vm.clients = USER_SETTINGS.user.clients;
        vm.clientList.values = _.forEach(vm.clients, function(eachItem) {
            eachItem.LABEL = eachItem.client_name;
            eachItem.VALUE = eachItem.client_key;
        });
        vm.clientList ={
            param_name: "Client",
            values: vm.clientList.values,
            label_name: "label",
            value_name: "value",
            label: "",
            selected:[]
        };

        vm.allUserList = {
            param_name: "User List",
            values: [],
            label_name: "label",
            value_name: "value",
            label: "All User's List",
            selected:[]
        };

        vm.addUser = function(){
            var modalObj = {};
            modalObj.template = "app/components/admin/users/user-add-tpl.html";
            modalObj.controler = "usersAddCtrl as ctrl";
            modalObj.size = "lg";
            modalObj.keyboard = false;
            modalObj.backdrop = "static";
            $scope.openModal(modalObj, vm.data);
        };

        vm.refresh = function(){
            getData();
        };

         // get the clientList initail load of page
		vm.getClientList = function() {
            ClientAdminFactory.getAllClients().then(function(res) {
                vm.clients = [];
                angular.forEach($parse('data.clientResult.clientList')(res), function(client) {
                    vm.clients.push({
                        id: client.client_key,
                        name: client.client_name
                    });
                });
                // vm.clients = $parse('data.clientResult.clientList')(res);
            });
        };

        vm.getAllUsersList = function(){
            UserAdminFactory.getAllUserList().then(function (data) {
                if( data && data.callSuccess === "1" ) {
                    //vm.usersList = data.user;

                    vm.allUserList.values = _.forEach(data.data, function(eachItem) {
                        eachItem.LABEL = eachItem.DISPLAY_NAME;
                        eachItem.VALUE = eachItem.SSO_ID;
                    });
                    console.log(vm.allUserList);
                }else{
                    AlertService.add("error", "User could not be found.", 4000);
                }
                vm.loading = false;
            });
        };

        vm.getClientList();
        vm.getAllUsersList();

        vm.onClientChange = function() {
            getData();
        };

        var gridUpdateListener = $rootScope.$on('gridUpdate', function(event, args) {
            getUpDate(args);
        });

        $scope.$on('$destroy', function () {
            $log.log("Unregistering listener");
            gridUpdateListener();
        });

        vm.getReportData = function() {
            console.log(vm.allUserList);
            vm.hideLoader = false;
            $scope.$broadcast("showLoader", true);
            vm.loading = true;
            vm.gridData = [];
            vm.gridData.DATA = [];
            var tempSsoId = [];
            
            _.forEach(vm.allUserList.selected, function(eachItem) {
                tempSsoId.push(eachItem.VALUE);
            });

            GridFactory.getDataCustom($state.current.api.get, {client_key : vm.filter.client_key, sso_id_filter : tempSsoId.toString()}).then(function (data) {
            if (data.callSuccess === "1") {
                    vm.data = data;
                    $scope.$broadcast("dataLoaded", data);
                    $scope.$broadcast("showLoader", false);
                } else {
                    AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                }
            });
        };

        var getUpDate = function(args) {
            $scope.$broadcast("showDataRefresh", true);
            var params = args;
            var _filterObj = {};
            GridFactory.getDataUpdate(vm.api,params).then(function (data) {
                if (data.callSuccess === "1") {
                    $scope.$broadcast("showDataRefresh", false);
                    $scope.$broadcast("dataUpdate", {filter:_filterObj,data:data.data});
                } else {
                    $scope.$broadcast("showDataRefresh", false);
                    AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                }
            });
        }


        var getData = function() {
            $scope.$broadcast("showLoader", true);
            vm.loading = true;
            vm.gridData = [];
            vm.gridData.DATA = [];
            GridFactory.getDataCustom($state.current.api.get, vm.filter).then(function (data) {
            if (data.callSuccess === "1") {
                    vm.data = data;
                    $scope.$broadcast("dataLoaded", data);
                    $scope.$broadcast("showLoader", false);
                } else {
                    AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                }
            });
        }


        $timeout(function() {
            //getData();
        }, 5);


        $scope.ctrlFn = function(_callName,_data,_type) {

            if(_type === 'columnTemplate'){
                GridSaveFactory.colTemplatesUpdate($state.current.api.grid_id,_callName,_data);
            }else if(_callName === 'userDetail'){
                _data = {user_id:_data.rowData.SSO_ID,selected_client_key:_data.rowData.CLIENT_KEY }
                $state.go('admin.users-list.user.details' ,  {data :_data });
            }else if( _callName === 'userDetailModule' ) {
                _data = {user_id:_data.rowData.SSO_ID }
                $state.go('admin.users-list-module.user.details' ,  {data :_data });
            }

        };



        //FOR ADD USER
        $scope.openModal = function (modalObj,_data) {
            $scope.rowData = _data.rowData;
            $scope.gridData = _data;
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: modalObj.template,
                controller: modalObj.controler,
                size: modalObj.size,
                backdrop: modalObj.backdrop,
                keyboard: modalObj.keyboard,
                resolve: {
                    rowData: function () {
                        return $scope.rowData;
                    },
                    gridData: function () {
                        return $scope.gridData;
                    }
                }
            });

            modalInstance.result.then(function (selectedItem) {
                $scope.selected = selectedItem;
            }, function () {
                $log.info('Modal dismissed at: ' + new Date());
                $log.info('$scope.rowData' , $scope.rowData);
            });
        };

    }

    function userAssignCtrl($uibModalInstance, GlobalService, JsonObjectFactory, GENERAL_CONFIG, AlertService,rowData,UserAdminFactory) {
        var vm = this;
        vm.title = "Assigned Users";
        vm.userAssignedAction = {
            targetDate : '',
            assign : [],
            comment_key : '',
            description: []
        }

        // Endpoints specific to Owner Assign Modal(Save,Get,Delete)
        var urls = {
            saveUserAssignList: GENERAL_CONFIG.base_url+'/saveModuleOwner',
            getUserAssignList: GENERAL_CONFIG.admin_api_url+'/getTaskOwner',
            removeUserAssignList: GENERAL_CONFIG.admin_api_url+'/removeTaskOwner'
        };

        // OnInit Function
        vm.Oninit = function() {
            var getUserAssignedParams = {
                "task_key": rowData.taskKey
            };
            UserAdminFactory.userAssignedList(getUserAssignedParams, urls.getUserAssignList).then(function (data) {
                if( data && data.callSuccess === "1" ) {
                    if(data.taskOwnerList){
                        _.forEach(data.taskOwnerList, function(item) {
                            vm.userAssignedAction.assign.push({NAME: item.name,  VALUE: item.value});
                        });
                    }
                }else {
                     AlertService.add("Error", "Failed to fetch assigned user data", 2000);
                }
            });
        }
        vm.Oninit();


        // Fetches the search data of user list
        vm.getUser = function (val) {
            var params = {
                "action_id": 76,
                "search_string": val
            };
            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
                if (data.errorMessage && data.errorMessage.length > 0 && data.errorMessage !== 'null') {
                    AlertService.add("error", data.errorMessage);
                } else {
                    if (data.jsonObject.length) {
                        return data.jsonObject;
                    }
                }
            });
        };

        // Selects the user from dropdown user list
        vm.assignUser = function (user) {
            vm.userAssignedAction.assign.push(user);
        }

        // Removes the selected user from the list
        vm.removeUser = function (user, index) {
            var userAssignedRemoveParams = {
                "task_key": rowData.taskKey,
                "owner_sso_id": user
            };
            UserAdminFactory.userAssignedList(userAssignedRemoveParams, urls.removeUserAssignList)
            .then(function(data) {
                if(data && data.callSuccess === "1") {
                    vm.userAssignedAction.assign.splice(index, 1);
                    AlertService.add("success", "record deleted successfully...", 4000);
                } else {
                    AlertService.add("Error", "Failed to delet the data...", 4000);
                }
            });
        }



        // Save the added user list
        vm.save = function() {
            var userAssignedSaveParams = {
                "task_key": rowData.taskKey,
                "owner_list": JSON.stringify(vm.userAssignedAction.assign),
                "description": vm.userAssignedAction.description
            };
            UserAdminFactory.userAssignedList(userAssignedSaveParams, urls.saveUserAssignList).then(function (data) {
                if( data && data.callSuccess === "1" ) {
                    AlertService.add("success", "User has been added successfully", 2000);
                    vm.cancel();
                }else{
                	 AlertService.add("warning",data.errorMessage, 2000);
                }
            });
        }

        //Modal cancel Method
        vm.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        }
    }

    function userExpiryReportCtrl($rootScope,$scope,$state,$log,GridFactory,AlertService,$timeout,ModalFactory,$uibModal,$parse,GridSaveFactory,ClientAdminFactory,GENERAL_CONFIG, USER_SETTINGS, workspaceFactory, GlobalService) {
        var vm = this;
        vm.name = "userListCtrl";
        vm.data = [];
        vm.api = $state.current.api.get;
        // vm.filter = {
        //     client_key: JSON.stringify( USER_SETTINGS.user.client.client_key)
        // };
        vm.filter = USER_SETTINGS.user.client
        vm.clients = [];
        vm.clients = USER_SETTINGS.user.clients;
        console.log(vm.filter, "vm.filter");

        console.log(USER_SETTINGS, "USER_SETTINGS");
        var all_params_data = '';
        $scope.action_key = '32938';
        $scope.grid_key = '6013';
        $scope.screen_key = '11505';
        $scope.screen_key = workspaceFactory.activeScreen.screen_key;

        vm.types = [
            {name : "PwC Users", value : "0"},
            {name : "Non-PwC Users", value : "1"},
            {name : "All", value : "2"}
        ];
        vm.selectedType = vm.types[0];

        var filterParams = {
            tax_year: GlobalService.globalParams.tax_year,
            scenario: GlobalService.globalParams.scenario,
            jcd_key: GlobalService.globalParams.jcd_key,
            screen_key: "11505",
            user_key: vm.selectedType.value,
            client_key: USER_SETTINGS.user.client.client_key
        };
        var all_params = GridFactory.getExcelUrl(filterParams);

        angular.forEach(all_params, function (value, key) {
            all_params_data = all_params_data + '&' + key + '=' + value;
        });

        $scope.all_filter_data = all_params_data;

        vm.showUserExpiryModal = function(data){
            var modalObj = {};
            modalObj.template = "app/components/admin/users/user-report-tpl.html";
            modalObj.controler = "userReportCtrl as ctrl";
            modalObj.size = "lg";
            modalObj.keyboard = false;
            modalObj.backdrop = "static";
            $scope.openModal(modalObj, data);
        };

        vm.refresh = function(){
            getData();
        };

        // get the clientList initail load of page
		vm.getClientList = function() {
            ClientAdminFactory.getAllClients().then(function(res) {
                vm.clients = [];
                angular.forEach($parse('data.clientResult.clientList')(res), function(client) {
                    vm.clients.push({
                        id: client.client_key,
                        name: client.client_name
                    });
                });
                // vm.clients = $parse('data.clientResult.clientList')(res);
            });
        };
       // vm.getClientList();

        vm.userExpiryClientChange = function() {
            var filterParams = {
                tax_year: GlobalService.globalParams.tax_year,
                scenario: GlobalService.globalParams.scenario,
                jcd_key: GlobalService.globalParams.jcd_key,
                screen_key: "11505",
                user_key: vm.selectedType.value,
                client_key: USER_SETTINGS.user.client.client_key
            };
            var all_params = GridFactory.getExcelUrl(filterParams);
    
            angular.forEach(all_params, function (value, key) {
                all_params_data = all_params_data + '&' + key + '=' + value;
            });
    
            $scope.all_filter_data = all_params_data;
            getData();
        };


        var gridUpdateListener = $rootScope.$on('gridUpdate', function(event, args) {
            getUpDate(args);
        });

        $scope.$on('$destroy', function () {
            $log.log("Unregistering listener");
            gridUpdateListener();
        });

        var getUpDate = function(args) {
            $scope.$broadcast("showDataRefresh", true);
            var params = args;
            var _filterObj = {};
            GridFactory.getDataUpdate(vm.api,params).then(function (data) {
                if (data.callSuccess === "1") {
                    $scope.$broadcast("showDataRefresh", false);
                    $scope.$broadcast("dataUpdate", {filter:_filterObj,data:data.data});
                } else {
                    $scope.$broadcast("showDataRefresh", false);
                    AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                }
            });
        }

        var getData = function() {
            $scope.$broadcast("showLoader", true);
            vm.loading = true;
            vm.gridData = [];
            vm.gridData.DATA = [];
            GridFactory.getDataCustom($state.current.api.get, {client_key:vm.filter.client_key, user_key:vm.selectedType.value}).then(function (data) {
            if (data.callSuccess === "1") {
                    vm.data = data;
                    $scope.$broadcast("dataLoaded", data);
                    $scope.$broadcast("showLoader", false);
                } else {
                    AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                }
            });
        }

        $timeout(function() {
            getData();
        }, 5);

        $scope.ctrlFn = function(_callName,_data,_type) {
            if(_type === 'form'){
                vm.showUserExpiryModal(_data);
            }else if(_type === 'columnTemplate'){
                GridSaveFactory.colTemplatesUpdate($state.current.api.grid_id,_callName,_data);
            }else if(_callName === 'userDetail'){
                _data = {user_id:_data.rowData.SSO_ID,selected_client_key:_data.rowData.CLIENT_KEY }
                $state.go('admin.users-list.user.details' ,  {data :_data });
            }else if( _callName === 'userDetailModule' ) {
                _data = {user_id:_data.rowData.SSO_ID }
                $state.go('admin.users-list-module.user.details' ,  {data :_data });
            }
        };

        //FOR ADD USER
        $scope.openModal = function (modalObj, _data) {
            $scope.rowData = _data.rowData;
            $scope.gridData = _data;
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: modalObj.template,
                controller: modalObj.controler,
                size: modalObj.size,
                backdrop: modalObj.backdrop,
                keyboard: modalObj.keyboard,
                resolve: {
                    rowData: function () {
                        return $scope.rowData;
                    },
                    userReportData: function () {
                        return $scope.gridData;
                    },
                    userExpiryClientKey: function(){
                        return vm.filter.client_key;
                    }
                }
            });

            modalInstance.result.then(function (selectedItem) {
                $scope.selected = selectedItem;
            }, function () {
                $log.info('Modal dismissed at: ' + new Date());
                $log.info('$scope.rowData' , $scope.rowData);
            });
        };
    }

    function userReportCtrl($rootScope,$scope,$state,$log,AlertService,$timeout,$uibModalInstance,UserAdminFactory,SERVER_CONFIG,GENERAL_CONFIG, userReportData, userExpiryClientKey, $filter) {
        var vm = this;
        vm.title = "Add User(s)";
        vm.user = {};
        vm.loading = false;
        vm.display = "none";
        vm.sso_id = "";
        vm.active = 1;
        vm.updating = false;
        vm.settings = SERVER_CONFIG.configSettings;

        /** LDAP Search by SSO ID logic moved to LDAP Search by (Pwc) Email Id.**/
        vm.callType = 'pwc_ldap';
        vm.email_id;
        vm.userExpiryRecords = [];
        vm.UserData = angular.copy(userReportData);

        angular.forEach(vm.UserData, function(item, index){
            item.data.opened = false;
            vm.userExpiryRecords.push(item.data);
        });

        vm.dateOptions = {
            dateDisabled: false,
            formatYear: 'yyyy',
            startingDay: 1,
            showWeeks: false
        };

        vm.startDate = new Date();
        vm.endDate = '';

        console.log("start date pulled",vm.startDate );
        console.log("end date pulled",vm.endDate );

        if (!vm.startDate) {
            vm.startDate = new Date();
        }

        if (!vm.endDate) {
            vm.endDate = new Date("12/31/9999");
        }

        $scope.today = function() {
            $scope.dt = new Date();
        };
        $scope.today();

        $scope.startdt = {
            opened: false
        };

        $scope.enddt = {
            opened: false
        };

        $scope.startdtopen = function() {
            $scope.startdt.opened = true;
        };

        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        };

        vm.removeRow = function(index){
            vm.userExpiryRecords.splice(index, 1);
        }

        vm.saveUserExpiryReport = function(){
            vm.userReports = [];
            angular.forEach(vm.userExpiryRecords, function(item, index) {
                vm.userReports.push({
                    client_key: userExpiryClientKey,
                    user_sso: item.SSO_ID,
                    expiry_date: $filter('date')(new Date(vm.startDate), 'MM/dd/yyyy')
                });
            });

            UserAdminFactory.saveUserExpiryReportData(vm.userReports).then(function (data) {
                if( data && data.callSuccess === "1" ) {
                    AlertService.add("success", "User Expiry Dates updated successfully.", 2000);
                    vm.updating = false;
                    $rootScope.$emit('gridUpdate', {sso_id:data.sso_id });
                }else{
                	 AlertService.add("warning", data.errorMessage, 2000);
                	 vm.updating = false;
                }
            });
        };
    }

    return controllers;

});
