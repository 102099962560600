const { forEach } = require("lodash");

define([
    'angular'
], function () {
    'use strict';
    let services = angular.module('app.efileService', []).factory("EfileFactory", ['$http', 'GENERAL_CONFIG', 'JsonObjectFactory', 'AlertService', 'USER_SETTINGS','$filter','SERVER_CONFIG',
        function ($http, GENERAL_CONFIG, JsonObjectFactory, AlertService, USER_SETTINGS,$filter,SERVER_CONFIG) {
            const STATES_ACTION_KEY = 'yh4p2z';
            const FILING_GROUP_ACTION_KEY = '6161k7';
            const STATE_FILING_GROUP_ACTION_KEY = 'y7xqs1';
            const TAX_PAYER_ACTION_KEY = 'iobyv3';
            const GET_FORM_OVERRIDE_HISTORY = 'mi11tr';
            const GET_System_amount_value = '20ovw6';
            // const saveFormOverrideAmount_action_key = 'ppz03b';
            const JCD_KEY = 250;
            const STATE_JCD_KEY = 253;
            const efileFactory = {};
            efileFactory.submissionTabs = [];
            efileFactory.ENV_FOR_PROCESS_SPLIT = "PRODUCTION";
            efileFactory.taxDocUrl = '';
            efileFactory.canDoProdSubmit = SERVER_CONFIG.configSettings.efile_consol_prod_submission_users ?
                                            SERVER_CONFIG.configSettings.efile_consol_prod_submission_users.includes(USER_SETTINGS.user.sso_id) : false;

            const userMap = {
                'USER':1,//Read Only
                'EDITOR':2,//Edit
                'ADMIN':3,//Test Submit
                'DEVELOPER':4,//Prod Submit
                'SUPER_ADMIN':5//Admin
            }
            const requestUrlBuilder = {
                url: '',
                isFirstParam: true,
                set: function (url) {
                    this.url = url;
                    this.isFirstParam = true;
                    return this;
                },
                get: function () {
                    return this.url;
                },
                appendEndpoint: function (endpoint) {
                    this.url = this.url + endpoint;
                    return this;
                },
                appendParam: function (paramName, paramVal) {
                    if (!this.url || this.url.length === 0) throw Error('Bad Url');
                    if (paramName !== undefined && paramVal !== undefined) {
                        this.url = this.url + (this.isFirstParam ? '?' : '&') + paramName + '=' + paramVal;
                        this.isFirstParam = false;
                    }
                    return this;
                }
            };

            efileFactory.errorListColumns = [
                {title:'Entity',data:'ENTITY'},
                {title:'Error Desc',data:'ERROR_DESC'},
                {title:'Error Type',data:'ERROR_TYPE'},
                {title:'Form',data:'FORM'},
                {title:'Form Sub',data:'FORM_SUB'},
                {title:'Key Desc',data:'KEY_DESC'},
                {title:'Parent Element Name',data:'PARENT_ELEMENT_NAME'},
                {title:'Parent SEQ',data:'PARENT_SEQ_NO'},
                {title:'Table Name',data:'TABLE_NAME'}
            ];
    
            efileFactory.formListColumns = [
                {title:'Entity',data:'ENTITY'},
                {title:'Form',data:'IRSFORM'},
                {title:'Form Sub',data:'FORM_SUB'},
                {title:'Parent Element Name',data:'PARENT_ELEMENT_NAME'},
                {title:'Source',data:'SRC'}
            ]
            efileFactory.entityListColumns = [
                {title:'Entity',data:'ENTITY'},
                {title:'Form',data:'IRSFORM'},
                {title:'Form Sub',data:'FORM_SUB'},
                {title:'Parent Element Name',data:'PARENT_ELEMENT_NAME'}
            ]
            efileFactory.diagListColumns = [
                {title:'Entity',data:'ENTITY'},
                {title:'Element Name',data:'ELEMENT_NAME'},
                {title:'Line No',data:'LINE_NO'},
                {title:'Source System',data:'SOURCE_SYSTEM'},
                {title:'User Name',data:'USERNAME'}
            ]
            efileFactory.submissionListColumns = [
                {title:'Entity',data:'IRS_ID'},
                {title:'Manifest File',data:'MANIFEST_EXISTS',render:(data,type,row)=> renderDownloadLink(data,'manifest',row.IRS_ID)},
                {title:'Acknowledgement File',data:'ACKNOWLEDGEMENT_EXISTS',render:(data,type,row)=> renderDownloadLink(data,'ack',row.IRS_ID)},
                {title:'Submission Receipt',data:'SUBMISSION_RECEIPT_EXISTS',render:(data,type,row)=> renderDownloadLink(data,'receipt',row.IRS_ID)},
                {title:'Submission Zip',data:'ZIP_FILE_NAME',render:(data,type,row)=> renderDownloadLink(data,'zip',row.IRS_ID)},
                {title:'IFA File',data:'IFA_FILE',render:(data,type,row)=> renderDownloadLink(data,'ifa',row.IRS_ID)},
                {title:'Submission Indicator',data:'ENVIRONMENT'},
                {title:'Accepted',data:'ACCEPTED'},
                {title:'Submitted By',data:'CREATED_BY'},
                {title:'Submitted Date',data:'CREATED_TS',render:(data)=> $filter('date')(data,'medium')},
                {title:'Actions',data:null,render:(data,type,row)=>renderActions(data,type,row)}
            ]
            function renderDownloadLink(data, type,irsId) {
                if(!data) return data;
                return `<a ${type === "ack" && data === "No" ? 'class="text-red"' : ""} ng-click="ctrl.downloadFile('${type}','${irsId}')">${data}</a>`;
            }

            function renderActions(data,type,row){
                if(row.ENVIRONMENT.toLowerCase() !== efileFactory.ENV_FOR_PROCESS_SPLIT.toLowerCase()) return '';

                const markAsReviwedBtn = `<a ng-click="ctrl.markAsReviewed('${row.IRS_ID}')">Complete Review</a>`;
                const uploadBtn = `<a ng-click="ctrl.uploadReviewAttachments('${row.IRS_ID}')">Upload</a>`;
                const viewBtn = `<a ng-click="ctrl.uploadReviewAttachments('${row.IRS_ID}',true)">View</a>`;
                const sendToIRSBtn = `<a ng-if="!ctrl.submitting" ng-click="ctrl.sendToIRS('${row.ENVIRONMENT}','${row.IRS_ID}')">Send To IRS</a>`;
                const rejectBtn = `<a ng-if="!ctrl.submitting" class="text-red" ng-click="ctrl.discardReturn('${row.IRS_ID}')">Reject</a>`;
                const seperator = `<span ng-if="!ctrl.submitting">/</span>`;
                const loader = `<div ng-if="ctrl.submitting" class="text-center"><i class="fa fa-spinner fa-spin"></i></div>`;

                if(!efileFactory.canDoProdSubmit){
                        return viewBtn;
                }

                if(row.SUBMISSION_RECEIPT_EXISTS === 'Yes'){
                    return uploadBtn
                }
                

                if(row.IS_REVIEWED === 'Y'){
                    return `${uploadBtn} ${seperator} ${loader} ${sendToIRSBtn}`;
                }

                return `${uploadBtn} ${seperator} ${markAsReviwedBtn} ${seperator} ${rejectBtn}`;
            }

            efileFactory.hasAccess = (requiredAccess) => USER_SETTINGS.user.client.user_type_key >= userMap[requiredAccess];
            efileFactory.getAllUSStates = function () {
                return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', {"action_code": STATES_ACTION_KEY});
            };

            efileFactory.getFilingGroups = function (taxYear, scenario, source, state = 'FEDERAL') {
                let actionCode = source === 'FEDERAL' ? FILING_GROUP_ACTION_KEY : STATE_FILING_GROUP_ACTION_KEY;
                let jcdKey = source === 'FEDERAL' ? JCD_KEY : STATE_JCD_KEY;
                return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject',
                    {
                        "action_code": actionCode,
                        "sso_id": USER_SETTINGS.user.sso_id,
                        "tax_year": taxYear,
                        "scenario": scenario,
                        "jcd_key": jcdKey,
                        "content_source": source,
                        "state": state
                    });
            };

            efileFactory.getTaxPayerList = function (taxYear, scenario, source, state = 'FEDERAL', consol_group_key) {
                return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject',
                    {
                        "action_code": TAX_PAYER_ACTION_KEY,
                        "sso_id": USER_SETTINGS.user.sso_id,
                        "tax_year": taxYear,
                        "scenario": scenario,
                        "jcd_key": JCD_KEY,
                        "content_source": source,
                        "state": state,
                        "filing_grp": consol_group_key
                    });
            };

            efileFactory.getEFileSystemsList = function (tax_year, scenario, tax_payer, fedState) {
                let jcdKey = fedState === 'FEDERAL' ? JCD_KEY : STATE_JCD_KEY;
                let taxPayer = fedState === 'FEDERAL' ? tax_payer : '';
                const promise = $http.post(GENERAL_CONFIG.admin_api_url + '/efile/getAllSystems?tax_year=' + tax_year + '&scenario=' + scenario +
                    '&jcd_key=' + jcdKey + '&tax_payer=' + taxPayer);
                return promise;
            };

            efileFactory.doSignOff = function (tax_year, scenario, tax_payer, systemId, fedState, is_signoff) {
                let jcdKey = fedState === 'FEDERAL' ? JCD_KEY : STATE_JCD_KEY;
                const promise = $http.post(GENERAL_CONFIG.admin_api_url + '/efile/doSignOff?tax_year=' + tax_year + '&scenario=' + scenario +
                    '&jcd_key=' + jcdKey + '&tax_payer=' + tax_payer + '&systemId=' + systemId + '&is_signoff=' + is_signoff);
                return promise;
            };

            efileFactory.undoSignOff = function (tax_year, scenario, tax_payer, systemId, fedState) {
                let jcdKey = fedState === 'FEDERAL' ? JCD_KEY : STATE_JCD_KEY;
                const promise = $http.post(GENERAL_CONFIG.admin_api_url + '/efile/undoSignOff?tax_year=' + tax_year + '&scenario=' + scenario +
                    '&jcd_key=' + jcdKey + '&tax_payer=' + tax_payer + '&systemId=' + systemId);
                return promise;
            };

            efileFactory.pushData = function (fedState, stateId, tax_year, scenario, filingGroup, groupName, tax_payer, cdrs, rptPrds, system_list, generateZip = "0") {
                let jcdKey = fedState === 'FEDERAL' ? JCD_KEY : STATE_JCD_KEY;
                const promise = $http.post(requestUrlBuilder.set(GENERAL_CONFIG.admin_api_url)
                                                            .appendEndpoint('/efile/pushEfileData')
                                                            .appendParam('filingType', fedState)
                                                            .appendParam('filingState', stateId)
                                                            .appendParam('tax_year', tax_year)
                                                            .appendParam('scenario', scenario)
                                                            .appendParam('filingGroupId', filingGroup)
                                                            .appendParam('groupName', groupName)
                                                            .appendParam('tax_payer', tax_payer)
                                                            .appendParam('cdrno', cdrs)
                                                            .appendParam('reportingperiod', rptPrds)
                                                            .appendParam('jcd_key', jcdKey)
                                                            .appendParam('systemList', system_list)
                                                            .appendParam('generateZip', generateZip)
                                                            .get());

                return promise.then((response) => {
                    if (response.data.callSuccess === "0" && !response.data.errorMessage) {
                        AlertService.add("error", "Data migration for selected systems failed to initiate!");
                    } else if (response.data.callSuccess === "0" && response.data.errorMessage) {
                        AlertService.add("error", response.data.errorMessage);
                    } else {
                        AlertService.add("success", "Data migration for selected systems has been successfully initiated!", 4000);
                    }
                }, () => AlertService.add("error", "Something went wrong!"));
            };

            efileFactory.getMigrationHistory = function (taxYear) {
                const promise = $http.post(requestUrlBuilder.set(GENERAL_CONFIG.admin_api_url)
                                                            .appendEndpoint('/efile/getMigrationHistory')
                                                            .appendParam('tax_year', taxYear)
                                                            .get());
                return promise;
            };

            efileFactory.generatePDFOnly = function (tax_year, scenario, tax_payer, filingGroup, filingType, groupName) {
                let jcdKey = filingType === 'STATE' ? STATE_JCD_KEY : JCD_KEY;
                const promise = $http.post(requestUrlBuilder.set(GENERAL_CONFIG.admin_api_url)
                    .appendEndpoint('/efile/generatePDFOnly')
                    .appendParam('tax_year', tax_year)
                    .appendParam('scenario', scenario)
                    .appendParam('jcd_key', jcdKey)
                    .appendParam('tax_payer', tax_payer)
                    .appendParam('filingGroupId', filingGroup)
                    .appendParam('filingType', filingType)
                    .appendParam('groupName', groupName)
                    .get());
                return promise;
            };

            efileFactory.loadSchema = function (filingYear, formType, schemaVersion, fileName, schemaZipFile) {
                var fd = new FormData();
                fd.append('file', schemaZipFile);
                const promise = $http.post(requestUrlBuilder.set(GENERAL_CONFIG.gtw_efile_api_url)
                    .appendEndpoint('/loadSchema')
                    .appendParam('filingYear', filingYear)
                    .appendParam('formType', formType)
                    .appendParam('schemaVersion', schemaVersion)
                    .appendParam('schemaZipFile', fileName)
                    .get(), fd, {
                        transformRequest: angular.identity,
                        headers: {
                            'Content-Type': undefined
                        }
                    });
                return promise;
            };
            efileFactory.discardReturn = function (submissionId,irsId) {

                const promise = $http.get(requestUrlBuilder.set(GENERAL_CONFIG.gtw_efile_api_url)
                    .appendEndpoint('/discardIRSReturn')
                    .appendParam('subId', submissionId)
                    .appendParam('irsId', irsId)
                    .get());
                return promise;
            };
            efileFactory.submitValidatedReturn = function (envType,submissionId,irsId) {

                const promise = $http.get(requestUrlBuilder.set(GENERAL_CONFIG.gtw_efile_api_url)
                    .appendEndpoint('/submitValidatedReturn')
                    .appendParam('env', envType)
                    .appendParam('subId', submissionId)
                    .appendParam('irsId', irsId)
                    .get());
                return promise;
            };
            efileFactory.submit = function (envType,submissionId) {

                const promise = $http.get(requestUrlBuilder.set(GENERAL_CONFIG.gtw_efile_api_url)
                    .appendEndpoint('/submit')
                    .appendParam('env', envType)
                    .appendParam('subId', submissionId)
                    .get());
                return promise;
            };
            efileFactory.pingIRS = function (envType) {
                const promise = $http.get(requestUrlBuilder.set(GENERAL_CONFIG.gtw_efile_api_url)
                    .appendEndpoint('/pingServer')
                    .appendParam('envType', envType)
                    .get());
                return promise;
            };
            efileFactory.getAck = function (submissionId,taxYear,scenario,jcdKey) {
                const promise = $http.get(requestUrlBuilder.set(GENERAL_CONFIG.gtw_efile_api_url)
                    .appendEndpoint('/pollAck')
                    .appendParam('subId', submissionId)
                    .appendParam('taxYear', taxYear)
                    .appendParam('scenario', scenario)
                    .appendParam('jcdKey', jcdKey)
                    .get());
                return promise;
            };
            efileFactory.syncXML = function (taxYear,submissionId,scenario,jcdKey) {
                const promise = $http.get(requestUrlBuilder.set(GENERAL_CONFIG.bulk_pdf_engine_api_url)
                    .appendEndpoint('/copyFederalXMLtoS3')
                    .appendParam('taxYear', taxYear)
                    .appendParam('submissionId', submissionId)
                    .appendParam('scenario', scenario)
                    .appendParam('jcdKey', jcdKey)
                    .get());
                return promise;
            };
            efileFactory.downloadAck = function (subId) {
                const promise = $http.get(requestUrlBuilder.set(GENERAL_CONFIG.gtw_efile_api_url)
                    .appendEndpoint('/downloadAckg')
                    .appendParam('irsId', '')
                    .appendParam('subId', subId)
                    .get(),{responseType:"arraybuffer"});
                return promise;
            };
            efileFactory.downloadSubStatusDoc = function (irsId,type) {
                const promise = $http.get(requestUrlBuilder.set(GENERAL_CONFIG.gtw_efile_api_url)
                    .appendEndpoint('/downloadSubStatusDoc')
                    .appendParam('irsId', irsId)
                    .appendParam('type', type)
                    .get(),{responseType:"arraybuffer"});
                return promise;
            };
            efileFactory.sqlInjection = function (submissionId,minVal,maxVal) {
                const promise = $http.get(requestUrlBuilder.set(GENERAL_CONFIG.gtw_efile_api_url)
                    .appendEndpoint('/sqlInject')
                    .appendParam('subId', submissionId)
                    .appendParam('minVal',minVal)
                    .appendParam('maxVal',maxVal)
                    .get());
                return promise;
            };
            efileFactory.viewPdf = function (submissionId,fileName) {
                const promise = $http.post(requestUrlBuilder.set(GENERAL_CONFIG.gtw_efile_api_url)
                    .appendEndpoint('/viewPdf')
                    .appendParam('submissionId', submissionId)
                    .appendParam('fileName',fileName)
                    .get(),{},{responseType:"arraybuffer"});
                return promise;
            };
            efileFactory.deletePdf = function (submissionId,fileName) {
                const promise = $http.post(requestUrlBuilder.set(GENERAL_CONFIG.gtw_efile_api_url)
                    .appendEndpoint('/deletePdf')
                    .appendParam('submissionId', submissionId)
                    .appendParam('fileName',fileName)
                    .get());
                return promise;
            };
            efileFactory.getPdfFileName = function (submissionId,fileName) {
                const promise = $http.post(requestUrlBuilder.set(GENERAL_CONFIG.gtw_efile_api_url)
                    .appendEndpoint('/getPdfFileName')
                    .appendParam('submissionId', submissionId)
                    .appendParam('fileType',fileName)
                    .get());
                return promise;
            };
            efileFactory.validate = function (submissionId, keyId, taxEntity) {
                let entityInfo = taxEntity || {};
                var promise = $http({
                    method: "post",
                    url:requestUrlBuilder.set(GENERAL_CONFIG.gtw_efile_api_url)
                    .appendEndpoint('/validate')
                    .appendParam('submissionId', submissionId)
                    .appendParam('keyId', keyId ? keyId : '')
                    .appendParam('reqType', keyId ? 'PDFGen' : '')
                    .get(),
                    data: JSON.stringify(entityInfo),
                    headers: {
                        "Content-Type": "application/json"
                    }
                });
                return promise;
            };
            efileFactory.validateBatch = function (submissionIds) {
                const promise = $http.get(requestUrlBuilder.set(GENERAL_CONFIG.gtw_efile_api_url)
                    .appendEndpoint('/validateBatch')
                    .appendParam('submissionIds', submissionIds)
                    .get());
                return promise;
            };
            efileFactory.efileBatchPollAck = function () {
                const promise = $http.get(requestUrlBuilder.set(GENERAL_CONFIG.gtw_efile_api_url)
                    .appendEndpoint('/pollAckBatch')
                    .get());
                return promise;
            };
            efileFactory.downloadBatchAcks = function (submissionIds) {
                const promise = $http.get(requestUrlBuilder.set(GENERAL_CONFIG.gtw_efile_api_url)
                    .appendEndpoint('/downloadSelectedAckg')
                    .appendParam('submissionIds', submissionIds)
                    .get(),{responseType:"arraybuffer"});              
                return promise;
            };
            efileFactory.generateBatch = function (submissionIds) {
                const promise = $http.get(requestUrlBuilder.set(GENERAL_CONFIG.gtw_efile_api_url)
                    .appendEndpoint('/generateBatch')
                    .appendParam('submissionIds', submissionIds)
                    .get());
                return promise;
            };
            efileFactory.efileBatchSubmission = function (submissionIds,env) {
                const promise = $http.get(requestUrlBuilder.set(GENERAL_CONFIG.gtw_efile_api_url)
                    .appendEndpoint('/submitBatch')
                    .appendParam('subIds', submissionIds)
                    .appendParam('env', env)
                    .get());
                return promise;
            };
            efileFactory.saveForm = function (submissionId,keyId,form,formSub,parentElementName,formId,elementName,sno,elementValue) {
                const promise = $http.get(requestUrlBuilder.set(GENERAL_CONFIG.gtw_efile_api_url)
                    .appendEndpoint('/saveElement')
                    .appendParam('submissionId', submissionId)
                    .appendParam('keyId', keyId)
                    .appendParam('form', form)
                    .appendParam('formSub', formSub)
                    .appendParam('parentElementName', parentElementName)
                    // .appendParam('parentSeqNo', parentSeqNo)
                    .appendParam('formId', formId)
                    .appendParam('elementName', elementName)
                    .appendParam('sno', sno)
                    .appendParam('elementValue', elementValue)
                    .get());
                return promise;
            };
            
            efileFactory.viewStyleSheet = function (submissionId,formId) {
                const promise = $http.get(requestUrlBuilder.set(GENERAL_CONFIG.gtw_efile_api_url)
                    .appendEndpoint('/viewStyleSheet')
                    .appendParam('formId', formId)
                    .appendParam('submissionId', submissionId)
                    .get());
                return promise;
            };
            
            efileFactory.deleteFormValues = function (submissionId,formId,elementName,sno) {
                const promise = $http.get(requestUrlBuilder.set(GENERAL_CONFIG.gtw_efile_api_url)
                    .appendEndpoint('/deleteElement')
                    .appendParam('submissionId', submissionId)
                    .appendParam('formId', formId)
                    .appendParam('elementName', elementName)
                    .appendParam('sno', sno)
                    .get());
                return promise;
            };
            
            //delete the form based on element review.
            efileFactory.deleteForm = function (submissionId,formId) {
                const promise = $http.get(requestUrlBuilder.set(GENERAL_CONFIG.gtw_efile_api_url)
                    .appendEndpoint('/deleteForm')
                    .appendParam('submissionId', submissionId)
                    .appendParam('formId', formId)
                    .get());
                return promise;
            };
            
            efileFactory.uploadPdf = function (subId,fileType, fileName, comments, schemaZipFile, isSignatureDocument) {
                var fd = new FormData();
                fd.append('file', schemaZipFile);

                var isSignatureDocumentAsParam = (isSignatureDocument && isSignatureDocument != null && isSignatureDocument == 'Y' ? 'Y' : 'N');
                const promise = $http.post(requestUrlBuilder.set(GENERAL_CONFIG.gtw_efile_api_url)
                    .appendEndpoint('/uploadPdf')
                    .appendParam('submissionId', subId)
                    .appendParam('fileType', fileType)
                    .appendParam('fileName', fileName)
                    .appendParam('comments', comments)
                    .appendParam('isSignatureDocument', isSignatureDocumentAsParam)
                    .get(), fd, {
                        transformRequest: angular.identity,
                        headers: {
                            'Content-Type': undefined
                        }
                    });
                return promise;
            };

            efileFactory.uploadFilesToDirectory = function (path,files) {
                var fd = new FormData();
                _.each(files,function(file) {
                    fd.append("files", file);                    
                })

                const promise = $http.post(requestUrlBuilder.set(GENERAL_CONFIG.gtw_efile_api_url)
                    .appendEndpoint('/uploadFilesToDirectory')
                    .appendParam('path', path)
                    .get(), fd, {
                        transformRequest: angular.identity,
                        headers: {
                            'Content-Type': undefined
                        }
                    });
                return promise;
            };

            efileFactory.delete = function (path) {
                const promise = $http.post(requestUrlBuilder.set(GENERAL_CONFIG.gtw_efile_api_url)
                    .appendEndpoint('/deleteFilesOrFolder')
                    .appendParam('path', path)
                    .get());
                return promise;
            };

            efileFactory.viewFiles = function (path) {
                const promise = $http.post(requestUrlBuilder.set(GENERAL_CONFIG.gtw_efile_api_url)
                    .appendEndpoint('/listFilesInDirectory')
                    .appendParam('path', path)
                    .get());
                return promise;
            };
            
            efileFactory.generateXMLOnly = function (tax_year, tax_payers,filingType,filingGroup,groupName) {
                let jcdKey = filingType === 'STATE' ? STATE_JCD_KEY : JCD_KEY;
                const promise = $http.post(requestUrlBuilder.set(GENERAL_CONFIG.admin_api_url)
                    .appendEndpoint('/efile/generateXMLsOnly')
                    .appendParam('tax_year', tax_year)
                    .appendParam('tax_payers', tax_payers)
                    .appendParam('filing_type', filingType)
                    .appendParam('group_key', filingGroup)
                    .appendParam('group_name', groupName)
                    .get());
                return promise;
            };
            efileFactory.refreshView = function (filingType) {
                const promise = $http.post(requestUrlBuilder.set(GENERAL_CONFIG.admin_api_url)
                    .appendEndpoint('/efile/mViewRefresh')
                    .appendParam('type', filingType)
                    .get());
                return promise;
            };
            efileFactory.uploadXMLKeyId = function (subId, fileName, uploadConsolFile,taxYear) {
                var fd = new FormData();
                fd.append('file', uploadConsolFile);

                const promise = $http.post(requestUrlBuilder.set(GENERAL_CONFIG.gtw_efile_api_url)
                    .appendEndpoint('/xmlUploadKeyId')
                    .appendParam('submissionId', subId)
                    .appendParam('fileName', fileName)
                    .appendParam('taxYear', taxYear)
                    .get(), fd, {
                        transformRequest: angular.identity,
                        headers: {
                            'Content-Type': undefined,
                            'enctype' :  'multipart/form-data'
                        }
                    });
                return promise;
            };
            efileFactory.download8453cPdf = function (subId) {
            	console.log("donwload 8453c service");
                const promise = $http.get(requestUrlBuilder.set(GENERAL_CONFIG.gtw_efile_api_url)
                    .appendEndpoint('/Generate8453CPdfContent')
                    .appendParam('subId', subId)
                    .get(),{responseType:"arraybuffer"});
                return promise;
            };

            efileFactory.download8879cfPdf = function (subId) {
            	console.log("download 8879c service");
                const promise = $http.get(requestUrlBuilder.set(GENERAL_CONFIG.gtw_efile_api_url)
                    .appendEndpoint('/Generate8879CPdfContent')
                    .appendParam('subId', subId)
                    .get(),{responseType:"arraybuffer"});
                return promise;
            };

            efileFactory.getSysOverideHis = function (taxYear, jobId, keyId, elementName, sno, subForm, 
                parentElementName,submission_id) {
                return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject',
                    {
                        "action_code": GET_FORM_OVERRIDE_HISTORY,
                        "tax_year": taxYear,
                        "job_id": jobId,
                        "key_id": keyId,
                        "element_name": elementName,
                        "sno": sno,
                        "sub_form": subForm,
                        "parent_element_name": parentElementName,
                        "submission_id":submission_id
                    });
            };

            efileFactory.getSysAmtValue = function (taxYear, jobId, keyId, elementName, sno, subForm, parentElementName) {
                return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject',
                    {
                        "action_code": GET_System_amount_value,
                        "tax_year": taxYear,
                        "job_id": jobId,
                        "key_id": keyId,
                        "element_name": elementName,
                        "sno": sno,
                        "sub_form": subForm,
                        "parent_element_name": parentElementName
                    });
            };
            efileFactory.getSubmissionLocked  =function (submissionId) {
                return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject?action_code=95vnw5',
                    {
                        "submission_id":submissionId
                    });
            };
            efileFactory.generateSubmissionXML = function ( submisionId,) {
                    return $http({
                        url: requestUrlBuilder.set(GENERAL_CONFIG.gtw_efile_api_url)
                        .appendEndpoint('/generateXMLInfo')
                        .appendParam('submissionId', submisionId)
                        .get(),
                        method: 'POST',
                        headers: {'Content-Type': 'application/json'},
                    });
            };
            efileFactory.bulkSyncXML = function ( taxYear,scenario,jcdKey) {
                    return $http({
                        url: requestUrlBuilder.set(GENERAL_CONFIG.bulk_pdf_engine_api_url)
                        .appendEndpoint('/copyFederalXMLtoS3')
                        .appendParam('taxYear', taxYear)
                        .appendParam('scenario', scenario)
                        .appendParam('jcdKey', jcdKey)
                        .get(),
                        method: 'GET',
                        headers: {'Content-Type': 'application/json'},
                    });
            };
            efileFactory.xmlVsElementsCompare = function ( irsSubmissionId ) {
                    return $http({
                        url: requestUrlBuilder.set(GENERAL_CONFIG.gtw_efile_api_url)
                        .appendEndpoint('/validateSubmissionXml')
                        .appendParam('irsSubmissionId', irsSubmissionId)
                        .get(),
                        method: 'GET',
                        headers: {'Content-Type': 'application/json'},
                    });
            };
            efileFactory.saveFormOverrideValue = function(params){
                // return JsonObjectFactory.saveJSONObj(GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=ppz03b',
                // {
                //     mef_obj_key:params.mef_obj_key,
                //     tax_year:params.tax_year,
                //     job_id:params.job_id,
                //     key_id:params.key_id,
                //     element_name:params.element_name,
                //     sno:params.sno,
                //     sub_form:params.sub_form,
                //     parent_element_name:params.parent_element_name,
                //     amount: params.amount,
                //     source_type: params.source_type,
                //     is_user_generated: params.is_user_generated,
                //     is_active: params.is_active
                // }
                 
                // );
                var promise = $http({
                    method: "post",
                    url: GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=ppz03b',
                    data: params
                }).then(function (response) {
                    return response.data;
                });
                return promise;
            }

            efileFactory.updateTaxDocUrl = function (irsId){
                    JsonObjectFactory.saveJSON(GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=6mtt1s',
                        {
                            'irs_id': irsId,
                            'sso_id': USER_SETTINGS.user.sso_id,
                            'file_key': efileFactory.taxDocUrl
                        }).then(response =>{

                            efileFactory.taxDocUrl = '';
                            AlertService.add("success","Successfully uploaded the review attachment");
                            $uibModalInstance.close();
                        })
                /* return JsonObjectFactory.saveJSON(GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=9zo5am',{ irsId, url: efileFactory.taxDocUrl }).then(()=>{
                    efileFactory.taxDocUrl = '';
                }); */
            }

            return efileFactory;
        }])
        
    return services;
});
